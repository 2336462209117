import React, { useState } from 'react';
import {
    Card,
    CardContent,
    styled,
    Fab,
    Popover,
} from '@mui/material';
import ReactMarkdown  from 'react-markdown'
import InfoIcon from '@mui/icons-material/Info';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    maxHeight: 'auto', // Set a maximum height for the card content
    maxWidth: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'auto', // Enable scrolling when content exceeds the max height
}));


/**
 * Card rendering markdown. Additional renderers available
 * See https://commonmark.org/help/
 * @param {String} text - The markdown text
 * @param {Number} drawerWidth - Margin on the lefs side (chart) 
 * @param {Number} descWidth - Width of card (as px)  
 *  * @param {Number} drawerWidth - Margin on the lefs side (chart) 
 *  */
const DescriptionCard = ({ text,marginLeft, descWidth }) => {

    const showText = text === undefined ? "### No information available" : text
    //console.log("TEST DescriptionCard",`${descWidth}px`,text,marginLeft, descWidth )
    return (
        <Card style={{width:`${descWidth}px`, marginLeft:`${marginLeft}px`}} variant="outlined">
            <StyledCardContent >
                <ReactMarkdown >
                    {showText}
                </ReactMarkdown >
            </StyledCardContent>
        </Card>
    );
};


const Overlay = ({ onClose, anchorEl, text }) => {
    const open = Boolean(anchorEl);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',

            }}

        >
            <DescriptionCard text={text} />
        </Popover>
    );
};

/**
 * A component that utilizes uPlot to render historical data with performance optimizations using hooks and batched updates.
 * Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb
 * @param {function} text - The event handler function.
 * @param {Array} drawerWidth - The array of x-axis data. 
 * @param {Array} marginBetween - The array of y-axis data. One or more series.
 *  */
const ChartDescription = ({ text, drawerWidth, marginBetween, descWidth }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleFabClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null); // Reset the anchor element when the overlay is closed
    };

    if (drawerWidth < 100) {
        return (
            <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', right: 16, bottom: 16, zIndex: 1 }}>
                    <Fab onClick={handleFabClick} color="info" aria-label="info" size="small">
                        <InfoIcon />
                    </Fab>
                </div>
                <Overlay anchorEl={anchorEl} onClose={handleClose} text={text} /> {/* Pass anchorEl and onClose to the Overlay */}
            </div>
        );
    }

    return (
            <DescriptionCard text={text} marginLeft={marginBetween} descWidth={descWidth}/>
    );
};

export default ChartDescription;
