import React, { useEffect, useState } from "react";
import "./Uplot.css";
import UplotReact from 'uplot-react';

/**
 * Wrapper for UplotReact. See https://www.npmjs.com/package/uplot-react#how-to-use
 * Target is created automatically and ignored  here.
 * @param {Object} props - uPlot chart options.
 */
function BasicPlot(props) {

    return (
        <UplotReact
            options={props.options}
            data={props.data}
            className={props.className}
            //target={target}
            onCreate={props.onCreate} //onCreate={(chart) => {}}
            onDelete={props.onDelete} //onDelete={(chart) => {}}
        />
    );
}

/**
 * Resisable uPlot component, read the available size of a defined container.
 * Height and width are set in relation 1:2
 * @param {Object} options - uPlot chart options.
 * @param {Object} data - uPlot data array, first element is x-Axis, others y-Axes. 
 * @param {String} className - Class name for CSS class linked to the div container where uPlot is attached to
 * @param {Function} onCreate - Hook called before creation. Provides chart object.
 * @param {Function} onDelete - Hook called before destruction. Provides chart object.
 * @param {String} sizeDefiningId - Id of the size providing element (add 100% width)
 * @param {Number} widthCor - Correction of width, if necessary (default 0)
 */
function ResizeablePlot({ options, data, className, onCreate, onDelete, sizeDefiningId, widthCor=0 }) {

    const [opts, setOpts] = useState(options);
    
    
    useEffect(() => {
        const parentElement = document.getElementById(sizeDefiningId);
        const handleResize = () => {
            try {
                const parentWidth = parentElement.offsetWidth - widthCor //+40; // oversize it
                const parentHeight = parentWidth / 2;
                const newSize = { width: parentWidth, height: parentHeight }
                setOpts(prevOpts => ({ ...prevOpts, ...newSize }));
            } catch (error) {
                console.error("Check the sizeDefiningId", error)
            }

        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [widthCor,sizeDefiningId]);

    return (
        <BasicPlot options={opts} data={data} className={className} onCreate={onCreate} onDelete={onDelete} />
    );
}

export default ResizeablePlot;