import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    MenuItem,
    Button,
    Stack,
} from "@mui/material";
import Brightness5Icon from '@mui/icons-material/Brightness5';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';



const ColorSettings = ({ setColorList, handleClose }) => {
    const navigate = useNavigate();

    const handleAppearance = (type) => {
        if (type === "dark") {
           setColorList({ mode: 'dark' })
        }
        else {
            setColorList({ mode: 'light' })
        }
    }

    return (
        <MenuItem
            sx={{ marginBottom: "1rem" }}
        >
            <Box>
                <Stack direction="row" spacing={1}>
                    <Button variant="outlined" size="small" startIcon={<DarkModeIcon />} onClick={() => handleAppearance("dark")}>
                    </Button>
                    <Button variant="outlined" size="small" startIcon={<Brightness5Icon />} onClick={() => handleAppearance("bright")}>
                    </Button>
                    <Button 
                    variant="outlined" 
                    size="small" 
                    startIcon={<FormatColorFillIcon />} 
                    onClick={() => {
                        navigate("/theme");
                        handleClose()
                      }}>
                    </Button>
                </Stack>
            </Box>
        </MenuItem >
    )

}

export default ColorSettings;