import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useDrawerWidth } from '../../size';


// Select target based on the model by clicking on a chip. The chips are sorted alphabetically. For small screens, a selector is provided
const ChartTargetSelector = ({ label, all, handler, value }) => {



    const drawerWidth = useDrawerWidth()
    const [selected, setSelected] = useState(() => {
        if (all === undefined || all === null) return null
        const ind = all.findIndex(item => item.navName === value);
        if (ind === -1) return null
        else return ind
    });

    const handleChange = (selection, index) => {
        setSelected(index)
        handler(selection)
    };

    // return if no data
    if ((all === undefined || all === null) && (value === undefined || value === null)) return null



    
    return (
        <Stack direction="row" spacing={1} sx={{ paddingRight: '2rem', paddingTop: drawerWidth === 0 ? '0' : '1rem', paddingBottom: drawerWidth === 0 ? '0' : '1rem' }} useFlexGap flexWrap="wrap">
            {all.map((obj, index) => (
                <Chip
                    className={drawerWidth === 0 ? 'mobile' : null}
                    key={index}
                    clickable={true}
                    sx={{
                        '& .MuiChip-label': {
                            fontSize: drawerWidth === 0 ? '0.875rem' : '1.2rem',
                            paddingTop: drawerWidth === 0 ? '1px' : '2px',

                        },
                    }}
                    style={{
                        height: drawerWidth === 0 ? '20px' : 'auto',
                    }}
                    color={(index === selected) ? "primary" : "default"}
                    label={obj.navName}
                    onClick={() => handleChange(obj, index)} />
            ))}
        </Stack>
    )
    // }
}

export default ChartTargetSelector