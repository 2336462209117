import React, { useEffect, useState } from "react";

import HistorianUPlot from "./HistorianUPlot";

import ChartDescription from "./ChartDescription";

import "react-datepicker/dist/react-datepicker.css"; // Import CSS

import { useDrawerWidth } from '../../size';
import {
    fetchChartDescription,
    fetchChartTimeSeriesData,
} from '../actions/api'




/**
 * Component defining the view section resolution of a chart page.
 * @param {Object} state - The event handler function.
 * @param {Object} chartObject - Object with complete chartview (including all charts, KPIs) definition. 
 * @param {Array} yArrays - The array of y-axis data. One or more series.
 */
const ChartGraphWorkspace = ({ state, chartObject, refreshChart }) => {
    //console.log(state, chartObject, chartObject.selectedSubGroupCharts)
    const drawerWidth = useDrawerWidth() // if 0 => mobile
    const [dataSets, setDataSets] = useState([]);
    const [chartDescriptions, setChartDescriptions] = useState([]);

    // Parse date time from string
    const parseDateTime = (dateTimeString) => {
        return new Date(dateTimeString);
    };



    // 1. Load charts (stored in chartObject.chars). All descriptions are provided (maybe change that in the future and add a list)
    // Take first element of campuslist
    useEffect(() => {
        if (chartObject.customerID !== null && chartObject.selectedCampus.length > 0) {
            const fetchData = async () => {
                const newDescriptions = await fetchChartDescription(chartObject.customerID, chartObject.selectedCampus[0].id);
                setChartDescriptions(newDescriptions)
            }

            fetchData();
        }
    }, [chartObject.customerID, chartObject.selectedCampus]);

    // 2. Add data sets
    useEffect(() => {

        // Process json data and bring it into the format for uplot
        const processData = (jsonData, start, end, nrOfPoints) => {
            const timestamps = jsonData.rows.map((row) =>
                parseDateTime(row.ts.val).getTime() / 1000 // (seconds since 1970-01-01 00:00:00)
            );

            const series = Array.from({ length: nrOfPoints }, () => []);
            // TODO change i values
            jsonData.rows.forEach((row) => {
                for (let i = 3; i < nrOfPoints + 3; i++) {
                    // check for  value  with and without val key
                    //series[i].push((row[`v${i}`] &&  row[`v${i}`].val) ? +row[`v${i}`].val : (row[`v${i}`] ? row[`v${i}`] : null));
                    series[i - 3].push((row[`v${i}`] && row[`v${i}`].val) ? +row[`v${i}`].val : (row[`v${i}`] ? row[`v${i}`] : null));
                }
            });

            return [timestamps, ...series];
        };

        const fetchData = async (idList, startDateTime, endDateTime, nrOfPoints) => {
            const data = await fetchChartTimeSeriesData(idList, startDateTime, endDateTime);
            const modData = processData(data, startDateTime, endDateTime, nrOfPoints)

            return modData
        }

        const updateDataSets = async () => {
            const promises = chartObject.charts.map(async (chart, index) => {
                //console.log("uPDATE DATA",chart)
                const modData = await fetchData(chart.points.map(m => m.id), chartObject.startDateTime, chartObject.endDateTime, chart.points.length);

                return modData
            });
            const newDataSetValues = await Promise.all(promises);

            setDataSets(newDataSetValues);
        };

        if (chartObject.charts.length > 0) {
            // console.log("uPDATE DATA",chartObject.charts)
            updateDataSets()
        }

    }, [chartObject.charts, chartObject.startDateTime, chartObject.endDateTime]);


    // TODO Add this
    const syncKey = 1
    const timeZone = "Africa/Freetown"
    // calculate width of chart description (inkl. margins)
    const marginBetween = (drawerWidth < 100) ? 0 : 14
    const descDrawerWidth = (drawerWidth < 100) ? 0 : 300


    // Create a Box (with graph and description) for each chart element in chartObject (chartObject.charts)
    return (
        <div id="graphContainer" style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: 'start', justifyContent: 'left' }}>
            {chartObject.selectedSubGroupChartType !== null && chartObject.startDateTime !== null && (
                <div>
                    {chartObject.charts.map((chart, index) => {
                        //console.log("dddddddddddddddddddddddd", chart, descDrawerWidth)
                        if (chartObject.selectedSubGroupChartType.includes("HistorianChart")) return (
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingBottom: "2rem" }} key={`div_hist_plot_${index}`}>
                                <HistorianUPlot

                                    syncKey={syncKey}
                                    tz={timeZone} // todo: take it from campus later
                                    chartDefs={chart}
                                    data={dataSets[index]}
                                    drawerWidth={descDrawerWidth + marginBetween}
                                />
                                <ChartDescription
                                    text={chartDescriptions.find(f => f.key === chart.spec)?.description}
                                    drawerWidth={drawerWidth}
                                    descWidth={descDrawerWidth}
                                    marginBetween={marginBetween}
                                />
                            </div>
                        )
                        else return <div>asdf</div>
                    })}
                </div>
            )}


        </div>
    );


}
//pointSelection={chartObject.selectedPointIds}
export default ChartGraphWorkspace;
