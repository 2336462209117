import i18next from './i18n'; 

// constants.js

const headerHeight = 55

export const HEADER_HEIGHT = `${headerHeight}px`
export const SIDEBAR_WIDTH_SM = headerHeight; // px
export const SIDEBAR_WIDTH_MD = headerHeight; // px
export const SIDEBAR_WIDTH_LG = headerHeight; // px
export const SIDEBAR_WIDTH_XL = headerHeight; // px

export const TASK_GROUPS = [
   {text: i18next.t('VENTILATION'), key:"VENTILATION", api:'Ventilation'},
   {text: i18next.t('HEATING_LOOPS'), key:"HEATING_LOOPS", api:'Heating Loops'},
   {text: i18next.t('ROOM_CONDITIONING'), key:"ROOM_CONDITIONING", api:'Room Conditioning'},
   {text: i18next.t('DOMESTIC_WATER'), key:"DOMESTIC_WATER", api:'Domestic Water'},
   {text: i18next.t('COOLING'), key:"COOLING", api:'Cooling'},
   {text: i18next.t('COOLING_LOOPS'), key:"COOLING_LOOPS", api:'Cooling Loops'},
   {text: i18next.t('DEFICIENCIES'), key:"DEFICIENCIES", api:'Deficiencies'},
   {text: i18next.t('NOTES'), key:"NOTES", api:'Notes'},
   {text: i18next.t('REPLACEMENT'), key:"REPLACEMENT", api:'Replacement'},
   {text: i18next.t('OTHERS'), key:"OTHERS", api:'Others'},
  ];

  export const TASK_PRIOS = [
    {text: i18next.t('HIGH'), key:"HIGH", api:'High'},
    {text: i18next.t('MEDIUM'), key:"MEDIUM", api:'Medium'},
    {text: i18next.t('LOW'), key:"LOW", api:'Low'},
   ];
   export const TASK_STATES = [
    {text: i18next.t('OPEN'), key:"OPEN", api:'Open'},
    {text: i18next.t('INPROGRESS'), key:"INPROGRESS", api:'In-Progress'},
    {text: i18next.t('DONE'), key:"DONE", api:'Done'},
    {text: i18next.t('CANCEL'), key:"CANCEL", api:'Cancel'},
    {text: i18next.t('REOPEN'), key:"REOPEN", api:'Re-Open'},
   ];
   export const DATERANGES = [
      {
          key:"LAST_DAY",
          value: "daily",
          id: "daily",
          selected: false
      },
      {
          key:"LAST_WEEK",
          value: "weekly",
          id: "weekly",
          selected: false
      },
      {
          key:"LAST_MONTH",
          value: "monthly",
          id: "monthly",
          selected: false
      },
      {
          key:"LAST_QUARTER",
          value: "quarterly",
          id: "quarterly",
          selected: false
      },
      {
          key:"LAST_YEAR",
          value: "yearly",
          id: "yearly",
          selected: false
      },
      {
          key:"CUSTOM",
          value: "custom",
          id: "custom",
          selected: true
      },
]
/**
 * Iterate the array and translate each key to the text key.
 * @param {Array} range - An array from constants.js
*  */
export const hotTranslate = (range) => {
    return range.map(state => ({
      ...state,
      text: i18next.t(`${state.key}`),
    }));
  };