import React, { useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./index";
import oxoia_theme from "./theme";
//import { CssBaseline, ThemeProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
} from "@mui/material/styles";
import Header from "./Components/PageStructure/Header";
import SideMenu from "./Components/PageStructure/SideMenu";
// import Footer from "./Components/Pages/Footer";
// import Home from "./Components/Pages/Home";
import Login from "./Components/Accounts/Login";
import Register from "./Components/Accounts/Register";
// import ChangePassword from "./Components/Accounts/ChangePassword";
// import Customers from "./Landing/Customers/Customers";
// import Partners from "./Components/Customers/Partners";

import Campuses from "./Components/Landing/Campuses";
import DetailCampus from "./Components/Campus/DetailCampus";

import Tasks from "./Components/Tasks/Tasks";
import EditTask from "./Components/Tasks/EditTask";
import DetailTask from "./Components/Tasks/DetailTask";
import Delete from "./Components/Tasks/Delete";
import Report from "./Components/Generic/Report";
import CreateTask from "./Components/Tasks/CreateTask";
import Settings from "./Components/Accounts/Settings";

// import Wishlist from "./Components/Pages/Wishlist";
import Charts from "./Components/Charts/Charts";
// import Kpis from "./Components/Dashboards/Kpis";
// import CampusMeasures from "./Components/Customers/CampusMeasures";
// import CampusKpis from "./Components/Customers/CampusKpis";
// import CampusControl from "./Components/Customers/CampusControl";
// import ChartSelector from "./Components/Dashboards/ChartSelector";
import ManualTheme from "./Components/Accounts/ManualTheme";

const defaultColors = [
  "#012A39",
  "#019C4B",
  "#FF0000",
  "#FF8500",
  "#BCC7D3",
  "#009C4A",
  "#ffffff",
  "#ffffff",
];

function App() {
  // Starting point for color setup
  const [colorList, setColorList] = useState(() => {
    let storedColors = JSON.parse(localStorage.getItem("colors"));
    let storedMode = localStorage.getItem("colorMode");
    if (storedColors === undefined || storedColors === null)
      storedColors = defaultColors;
    if (storedMode === undefined || storedMode === null || storedMode === "")
      storedMode = "light";
    return { colors: storedColors, mode: storedMode };
  });

  const [subMenu, setSubmenu] = useState(null);
  //const theme = oxoia_theme(colorList.colors, colorList.mode);

  // This provide auto generated CSS variables
  const theme = extendTheme(oxoia_theme(colorList.colors, colorList.mode));

  const setColorData = (val) => {
    let newDataSet = { ...colorList };

    if ("mode" in val) newDataSet = { ...newDataSet, mode: val.mode };
    if ("colors" in val) newDataSet = { ...newDataSet, colors: val.colors };
    setColorList(newDataSet);
  };

  return (
    // <ThemeProvider theme={theme}>
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/campuses"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu localOnly={false} menuEntries={subMenu} />
                <Campuses addSubMenu={setSubmenu} />
              </>
            }
          />
          {/* Ensure the route is defined */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/settings"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu localOnly={false} menuEntries={subMenu} />
                <Settings />
              </>
            }
          />
          <Route
            path="/theme"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu localOnly={false} menuEntries={subMenu} />
                <ManualTheme
                  setColorList={setColorList}
                  colorList={colorList}
                />
              </>
            }
          />
          {/* <Route path="/customers" element={<Customers />} />
          <Route path="/partners" element={<Partners />} /> */}
          <Route
            path="/campus-detail/:id"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <DetailCampus />
              </>
            }
          />
          {/* <Route path="/campus-measures/:id" element={
            <>
              <Header setColorList={setColorData} />
              <SideMenu />
              <CampusMeasures />
            </>
          } />
          <Route path="/campus-kpis/:id" element={
            <>
              <Header setColorList={setColorData} />
              <SideMenu />
              <CampusKpis />
            </>
          } />
          <Route path="/campus-control/:id" element={
            <>
              <Header setColorList={setColorData} />
              <SideMenu />
              <CampusControl />
            </> 
          } />*/}
          {/* <Route path="/portfolios" element={
            <>
              <Header setColorList={setColorData} />
              <SideMenu />
              <Portfolios />
            </>
          } />
          <Route path="/buildings" element={
            <>
              <Header setColorList={setColorData} />
              <SideMenu />
              <Buildings />
            </>
          } /> */}
          <Route
            path="/tasks"
            element={
              // <Tasks />
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <Tasks />
              </>
            }
          />
          <Route
            path="/tasks/:id/edit"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <EditTask />
              </>
            }
          />
          <Route
            path="/tasks/:id/detail"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <DetailTask />
              </>
            }
          />
          <Route
            path="/tasks/delete"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <Delete />
              </>
            }
          />
          <Route
            path="/tasks/report"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <Report />
              </>
            }
          />
          <Route
            path="/tasks/create"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <CreateTask />
              </>
            }
          />
          {/* <Route path="/change-password" element={<ChangePassword />} /> */}
          {/* <Route path="/language" element={<Language />} />
          <Route path="/languageselector" element={<LanguageSelector />} /> */}
          {/* <Route path="/wishlist" element={<Wishlist />} /> */}
          <Route
            path="/charts"
            element={
              <>
                <Header setColorList={setColorData} />
                <SideMenu />
                <Charts />
              </>
            }
          />
          {/* <Route path="/kpis" element={<Kpis />} />
          <Route path="/chartselector" element={<ChartSelector />} /> */}
        </Routes>
        {/* </main> */}
        {/* Include the Footer component */}
        {/* </AppContainer> */}
      </Router>
      {/* </ThemeProvider> */}
    </CssVarsProvider>
  );
}

export default App;
