import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import ChartTargetSelector from "./ChartTargetSelector";
import ChartGraphWorkspace from "./ChartGraphWorkspace";
import ChartButtons from "./ChartButtons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDrawerWidth } from "../../size";

import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import {
  fetchCustomerAccessibleCampus,
  fetchChartViews,
  fetchChartPoints,
  fetchChartTargets,
} from "../actions/api";

const defaultChartObject = {
  favId: "",
  favName: "My Fav",
  selectedCampus: [], // list, take first element of selectedCampus storage values
  chartData: [], // Complete data
  // chartGroup
  selectedChartGroupLabel: null, // required for autocomplete
  selectedChartGroupData: [], // data for further processing
  // sub group
  selectedChartSubGroupLabel: null, // required for autocomplete
  selectedChartSubGroupData: [],

  selectedSubGroupTarget: null,
  selectedChartDetails: {},
  selectedTargetNavName: null,
  selectedNavNames: [],
  selectedTargetId: null,
  targetData: [], // target recs
  targetIds: [], // target ids

  // all chart data
  charts: [],

  customerID: null,
  chartType: null,
  chartColors: null,
  rangeType: "custom",
  startDate: null,
  startTime: null,
  startDateTime: null,
  endDate: null,
  endTime: null,
  endDateTime: null,
  pointsData: [],
  selectedPointIds: [],
};

const Charts = () => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth(); // if 0 => mobile

  const [selectedCampus, setSelectedCampus] = useState(() => {
    const saved = localStorage.getItem("selectedCampus");
    return saved === null || saved.length === 0
      ? []
      : [JSON.parse(saved)]
          .flat()
          .map((camp) => (camp ? { ...camp, label: camp.name } : {}));
  });

  const [chartObject, setChartObject] = useState(() => {
    const saved = localStorage.getItem("chartObject");
    const parsed = JSON.parse(saved);

    if (parsed === null && selectedCampus.length > 0)
      return { ...defaultChartObject, selectedCampus: selectedCampus };
    else if (parsed !== null && selectedCampus.length > 0)
      return { ...parsed, selectedCampus: selectedCampus };
    else if (parsed !== null && selectedCampus.length === 0) return parsed;
    else return defaultChartObject;
  });

  const [state, setState] = useState({
    state: { refresh: false, starting: true },
    refresh: { refresh: false, starting: true },
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message

  useEffect(() => {
    localStorage.setItem("chartObject", JSON.stringify(chartObject));
  }, [chartObject]);

  useEffect(() => {
    let camps = selectedCampus;
    if (chartObject.selectedCampus[0]) {
      let existingObject = selectedCampus.find(
        (obj) => obj.id === chartObject.selectedCampus[0].id
      );
      if (!existingObject) {
        camps.unshift(chartObject.selectedCampus[0]);
        localStorage.setItem("selectedCampus", JSON.stringify(camps));
      }
    }
  }, [selectedCampus, chartObject.selectedCampus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerData = await fetchCustomerAccessibleCampus();
        if (customerData.campuses.length > 0) {
          const processedCampuses = customerData.campuses?.map((campus) => ({
            ...campus,
            sites: campus.sites.map((site) => ({
              ...site,
              campus_id: campus.id,
            })),
          }));
          setIsLoading(false);
          setChartObject((prevChartObject) => ({
            ...prevChartObject,
            customerID: customerData.customer_id,
            campusesData: processedCampuses,
          }));
        } else {
          setErrorMessage(t("No campuses available for this user."));
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage(t("Error fetching campuses data."));
        setIsLoading(false);
      }
    };
    fetchData();
  }, [t]);

  const organizedChartData = async (rawDataPromise) => {
    const rawData = await rawDataPromise;
    const uniqueChartGroupNames = [
      ...new Set(rawData.map((item) => item.chartGroup)),
    ];

    const result = uniqueChartGroupNames.map((name) => {
      const elementsOfSameGroup = rawData.filter((f) => f.chartGroup === name);
      return {
        chartGroupName: name,
        subGroups: elementsOfSameGroup,
      };
    });

    return { chartData: result };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (chartObject.customerID && chartObject.selectedCampus.length > 0) {
        const chartData = await organizedChartData(
          fetchChartViews(
            chartObject.customerID,
            chartObject.selectedCampus[0].id
          )
        );
        setChartObject((prevChartObject) => ({
          ...prevChartObject,
          ...chartData,
        }));
      }
    };
    fetchData();
  }, [chartObject.customerID, chartObject.selectedCampus]);

  useEffect(() => {
    const fetchData = async () => {
      if (chartObject.selectedCampus[0]) {
        const campusHaystackModelId = chartObject.campusesData.find(
          (campus) =>
            String(campus.id) === String(chartObject.selectedCampus[0].id)
        )?.campus_haystack_model_id;

        if (campusHaystackModelId) {
          const oxoia_command = `${chartObject.selectedSubGroupTarget},${campusHaystackModelId}`;
          const targets = await fetchChartTargets(
            chartObject.customerID,
            chartObject.selectedCampus[0].id,
            oxoia_command
          );
          setChartObject((prevChartObject) => ({
            ...prevChartObject,
            targetData: targets,
          }));
        }
      }
    };
    if (
      chartObject.selectedChartSubGroupLabel !== null &&
      chartObject.selectedCampus.length > 0
    ) {
      fetchData();
    }
  }, [
    chartObject.selectedChartSubGroupLabel,
    chartObject.campusesData,
    chartObject.selectedCampus,
    chartObject.selectedSubGroupTarget,
    chartObject.customerID,
  ]);

  const handleSelectCampus = (val) => {
    if (val !== null && val !== undefined) {
      setChartObject({
        ...chartObject,
        selectedCampus: val,
        selectedTargetNavName: null,
        selectedTargetId: null,
        targetData: [],
      });
      setSelectedCampus(val);
    }
  };

  const handleSelectChartGroup = (group) => {
    if (group !== null && group !== undefined) {
      setChartObject({
        ...chartObject,
        selectedChartGroupLabel: group.label,
        selectedChartGroupData:
          chartObject.chartData.find((f) => f.chartGroupName === group.label)
            ?.subGroups || [],
        selectedChartSubGroupLabel: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedSubGroupSearchFunc: null,
        selectedSubGroupChartType: null,
        selectedSubGroupCharts: [],
        selectedSubGroupKpis: [],
        selectedTargetNavName: null,
        selectedTargetId: null,
        targetData: [],
      });
    }
  };

  const handleSelectChartSubGroup = (subgroup) => {
    if (subgroup !== null && subgroup !== undefined) {
      const selectedDataElement = chartObject.selectedChartGroupData.find(
        (f) => f.chartSubGroup === subgroup.label
      );
      setChartObject({
        ...chartObject,
        selectedChartSubGroupLabel: subgroup.label,
        selectedChartSubGroupData: selectedDataElement || {},
        selectedSubGroupTarget: selectedDataElement?.target || null,
        selectedSubGroupSearchFunc: selectedDataElement?.searchFunc || null,
        selectedSubGroupChartType: selectedDataElement?.chartType || null,
        selectedSubGroupCharts: selectedDataElement?.charts || [],
        selectedSubGroupKpis: selectedDataElement?.kpis || [],
      });
    }
  };

  const handleObjectChange = (newData) => {
    setChartObject({
      ...chartObject,
      ...newData,
    });
  };

  const handleRefreshClick = () => {
    setState({ ...state, refresh: { ...state.state, refresh: true } });
    setTimeout(() => {
      setState({ ...state, refresh: { ...state.state, refresh: false } });
    }, 1000);
  };

  const handleNavNameChange = (selectedItem) => {
    if ("navName" in selectedItem) {
      const targetIds = chartObject.targetData.filter(
        (item) => item.navName === selectedItem.navName
      );

      const targets = targetIds.map((item) =>
        item.id.replace("@p:chartTest:r:", "")
      );

      setChartObject({
        ...chartObject,
        targetIds: targets,
        selectedTargetNavName: selectedItem.navName,
        selectedTargetId: selectedItem.id,
      });
    } else {
      setChartObject({
        ...chartObject,
        selectedTargetNavName: null,
        selectedTargetId: null,
        targetIds: [],
      });
    }
  };

  useEffect(() => {
    const fetchData = async (chartElement, index, targetId, functionName) => {
      let data = await fetchChartPoints(
        chartObject.customerID,
        chartObject.selectedCampus[0]?.id,
        targetId,
        chartElement.spec,
        functionName
      );
      if (Object.entries(data).length === 0) return;

      const prevCharts = chartObject.charts;
      prevCharts[index] = {
        title: chartElement.dis,
        spec: chartElement.spec,
        ...data,
      };

      setChartObject((prevChartObject) => ({
        ...prevChartObject,
        charts: prevCharts,
      }));
    };

    if (chartObject.selectedTargetId) {
      chartObject.selectedSubGroupCharts.forEach((element, index) => {
        fetchData(
          element,
          index,
          chartObject.selectedTargetId,
          chartObject.selectedSubGroupSearchFunc
        );
      });
    }
  }, [
    chartObject.selectedTargetId,
    chartObject.customerID,
    chartObject.charts,
    chartObject.selectedCampus,
    chartObject.selectedChartGroupLabel,
    chartObject.selectedSubGroupCharts,
    chartObject.selectedSubGroupSearchFunc,
  ]);

  return (
    <Workspace isLoading={isLoading} title={t("CHARTS")}>
      {errorMessage ? (
        <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
          {errorMessage}
        </Typography>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: `${drawerWidth === 0 ? "column" : "row"}`,
              alignItems: "center",
            }}
          >
            <GenericAutocomplete
              multiple={true}
              dataList={chartObject.campusesData || []}
              changeHandler={handleSelectCampus}
              value={chartObject.selectedCampus}
              size={`${drawerWidth === 0 ? "small" : "medium"}`}
              style={{
                paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
                minWidth: `${drawerWidth === 0 ? "100%" : "150px"}`,
                width: `${drawerWidth === 0 ? "100%" : "350px"}`,
              }}
              label={t("Campus")}
            />
            {drawerWidth > 100 && (
              <div
                style={{
                  paddingBottom: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <NavigateNextIcon />
              </div>
            )}
            {drawerWidth > 0 && drawerWidth < 100 && (
              <div
                style={{
                  paddingBottom: "1rem",
                  paddingLeft: "0.2rem",
                  paddingRight: "0.2rem",
                }}
              >
                <NavigateNextIcon />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: `${drawerWidth === 0 ? "100%" : "66%"}`,
              }}
            >
              <GenericAutocomplete
                dataList={(chartObject.chartData || []).map(
                  (item) => item.chartGroupName
                )}
                changeHandler={handleSelectChartGroup}
                size={`${drawerWidth === 0 ? "small" : "medium"}`}
                value={chartObject.selectedChartGroupLabel}
                label={t("CHARTCAT")}
                popperStyle={{
                  width: `${drawerWidth === 0 ? "300px" : "auto"}`,
                }}
                style={{
                  paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
                  paddingRight: `${drawerWidth === 0 ? "1rem" : null}`,
                  minWidth: `${drawerWidth === 0 ? "50%" : "150px"}`,
                  width: `${drawerWidth === 0 ? "50%" : "350px"}`,
                }}
              />
              {drawerWidth > 100 && (
                <div
                  style={{
                    paddingBottom: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                >
                  <NavigateNextIcon />
                </div>
              )}
              {drawerWidth > 0 && drawerWidth < 100 && (
                <div
                  style={{
                    paddingBottom: "1rem",
                    paddingLeft: "0.2rem",
                    paddingRight: "0.2rem",
                  }}
                >
                  <NavigateNextIcon />
                </div>
              )}
              <GenericAutocomplete
                dataList={(chartObject.selectedChartGroupData || []).map(
                  (subGroup) => subGroup.chartSubGroup
                )}
                changeHandler={handleSelectChartSubGroup}
                size={`${drawerWidth === 0 ? "small" : "medium"}`}
                value={chartObject.selectedChartSubGroupLabel}
                label={t("CHARTCATSUB")}
                popperStyle={{
                  placement: `${drawerWidth === 0 ? "left" : "bottom"}`,
                  width: `${drawerWidth === 0 ? "300px" : "auto"}`,
                }}
                style={{
                  paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
                  minWidth: `${drawerWidth === 0 ? "50%" : "150px"}`,
                  width: `${drawerWidth === 0 ? "50%" : "350px"}`,
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ChartTargetSelector
              handler={handleNavNameChange}
              value={chartObject.selectedTargetNavName}
              all={chartObject.targetData}
              label={t("TARGET")}
            />
          </div>
          <ChartButtons
            range={{
              changeHandler: handleObjectChange,
              defaultVal: chartObject.rangeType,
            }}
            points={{
              changeHandler: handleObjectChange,
              defaultVal: chartObject.selectedPointIds,
              allVal: chartObject.pointsData,
            }}
            dateTime={{
              changeHandler: handleObjectChange,
              defaultVal: chartObject,
            }}
            callHandler={{
              refresh: handleRefreshClick,
              refreshDisabled: false,
            }}
          />
          <ChartGraphWorkspace chartObject={chartObject} state={state} />
        </>
      )}
    </Workspace>
  );
};

export default Charts;
