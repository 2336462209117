import React from "react";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {useTheme} from '@mui/material';
import { useDrawerWidth } from '../../size';


const TaskTopicFilter = ({ label, tasks, topicHandler, selectedTopics }) => {

    const drawerWidth = useDrawerWidth()
    const theme = useTheme(); 

    const handleChange = (event) => {

        if("text" in event)
        {
            const found = selectedTopics.filter(f => f.key === event.key)
            if(found.length === 0) {
                topicHandler([...selectedTopics,event])
            } else {
                topicHandler(selectedTopics.filter(f => f.key !== event.key))
            }
        }
    };
    
    
    // return if no data
    if ((tasks === undefined || tasks === null) && (selectedTopics === undefined || selectedTopics === null)) return null

        return (
            <Stack direction="row" spacing={1} sx={{ paddingBottom: '1rem'}}  useFlexGap flexWrap="wrap">
                {tasks.sort((a, b) => a.text.localeCompare(b.text)).map((group, index) => (
                    <Chip 
                    key={index} 
                    style={{
                        height: drawerWidth === 0 ? '20px' : 'auto',
                        color: selectedTopics.filter(f => f.key === group.key).length > 0  ? theme.palette.primary.contrastText : 'initial',
                        backgroundColor: selectedTopics.filter(f => f.key === group.key).length > 0  ? theme.palette.primary.light : 'initial',
                    }} 
                    sx={{
                        '& .MuiChip-label': {
                            fontSize: drawerWidth === 0 ? '0.725rem' : '1.0rem',
                            paddingTop: drawerWidth === 0 ? '0px' :'3px',
                            paddingBottom: drawerWidth === 0 ? '0px' :'3px',
                            paddingLeft: drawerWidth === 0 ? '6px' :'12px',
                            paddingRight: drawerWidth === 0 ? '6px' :'12px',
                        },
                    }}
                    label={group.text} 
                    onClick={() =>handleChange(group)} />
                ))}
            </Stack>
        )
}

export default TaskTopicFilter