import React, { useState } from "react";
import Button from '@mui/material/Button';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';


// input: points={{changeHandler: handlePointChange, defaultVal:chartObject.selectedPointIds, allVal:chartObject.pointsData}}
export default function ChartSaveButton({ chartObject }) {

    // Anchor states
    const [anchor, setAnchor] = useState(null);
    const menuOpen = Boolean(anchor);

    // Data states
    

    // State updates


    // Handlers
    // Handle all clicks on buttons
    const handleClick = (event) => {
        setAnchor(event.currentTarget);

    };
   


    return (
        <>

            <Button
                id="save-button"
                //disabled={points.allVal.length === 0 ? true : false}
                aria-controls={menuOpen ? 'pts-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleClick}
            >
                <SaveAsOutlinedIcon />
            </Button>
            
        </>
    );
}