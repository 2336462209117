import "@fontsource/urbanist"; // Load all styles and weights of Urbanist
import "@fontsource/urbanist/400.css"; // Load regular weight specifically
import "@fontsource/urbanist/400-italic.css"; // Load italic variant
import { createTheme } from "@mui/material";
import darkScrollbar from "@mui/material/darkScrollbar";
import urbanist from "@fontsource/urbanist";
import "@fontsource/urbanist/400.css"; // Specify weight
import "@fontsource/urbanist/400-italic.css"; // Specify weight and style
import { HEADER_HEIGHT } from "./constants";

export default function oxoia_theme(
  colors = [
    "#012A39",
    "#019C4B",
    "#FF0000",
    "#FF8500",
    "#BCC7D3",
    "#009C4A",
    "#ffffff",
    "#ffffff",
  ],
  mode = "light"
) {
  const { palette } = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: colors[0],
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: colors[1],
      },
      error: {
        main: colors[2],
      },
      warning: {
        main: colors[3],
      },
      info: {
        main: colors[4],
      },
      success: {
        main: colors[5],
      },
      background: {
        default: colors[6],
        paper: colors[7],
      },
      // text: {
      //     primary: colors[6],
      //     secondary: colors[7],
      //     disabled: colors[7],
      // },
    },
  });

  const fontFamily = "Urbanist, sans-serif";
  const theme = createTheme({
    palette: palette,
    typography: {
      fontFamily: fontFamily, // Set Urbanist as the default font
    },

    // custom variables vor css
    cssVarPrefix: "ox",
    customVariables: {
      "--primary-color": palette.primary.main, // Example primary color value
      "--secondary-color": palette.secondary.main, // Example secondary color value
      "--font-family": fontFamily, // Example font family value
      "--font-size": "1rem", // Example font family value
      // Define additional custom variable values here
    },

    // Define your theme options here
    typography: {
      fontFamily: fontFamily,
    },
    components: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [urbanist],
        },
        styleOverrides: {
          body: {
            ...darkScrollbar(),
            fontFamily: fontFamily, // Ensure Urbanist is applied globally
            //color: `${palette.primary.contrastText}`,
            //fontFamily:"Urbanist","Roboto","Helvetica"
            //background: `linear-gradient(to right, ${palette.primary.main}, ${palette.secondary.main})`,

            // titles must be on backgroud
            "& h1": {
              color: `${palette.background.contrastText}`,
            },
          },
        },
      },

      // Name of the component
      MuiButtonGroup: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS

            height: "30px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "medium",
            background: palette.secondary.dark,
            color: palette.secondary.contrastText,
            textTransform: "none",
            height: "36px",
            minWidth: "36px",
            paddingLeft: "0px",
            paddingRight: "0px",
            //borderRadius: '20px', // => may move this to login/register
            "&.login": {
              // Additional styles for the subclass
              // Example:
              borderRadius: "20px",
            },
            "&.popup": {
              // Additional styles for the subclass
              // Example:
              background: palette.secondary.main,
              color: palette.secondary.contrastText,
            },
            "&.workspace": {
              // Additional styles for the subclass
              // Example:
              fontSize: "medium",
              paddingTop: "0px",
              paddingBottom: "0px",
              //fontSize: '0.875rem',
              minWidth: "64px",
              paddingLeft: "8px",
              paddingRight: "8px",
              background: palette.secondary.dark,
              color: palette.secondary.contrastText,
              "&.Mui-disabled": {
                opacity: 0.7,
                background: palette.info.main,
              },
              "&:hover": {
                backgroundColor: palette.secondary.light,
              },
              "&:focus": {
                outline: `2px solid ${palette.secondary.dark}`,
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&.header": {
              // Additional styles for the subclass
              // Example:
              marginRight: "0px",
              backgroundColor: palette.secondary.dark,
              color: palette.secondary.contrastText,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
            "&.headerMenu": {
              // Additional styles for the subclass
              // Example:
              padding: "4px",
              backgroundColor: palette.secondary.dark,
              color: palette.secondary.contrastText,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          // Name of the slot
          root: {
            //borderColor: palette.secondary.dark,
            //background: palette.secondary.main,
            color: palette.primary.dark,
            "&.sideMenu": {
              // Additional styles for the subclass
              // Example:
              marginRight: "0px",
              color: palette.primary.dark,
              //borderColor: palette.secondary.dark,
              //background: palette.secondary.main,
              //color:palette.secondary.contrastText,
              //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          // Name of the slot
          root: {
            //borderColor: palette.secondary.dark,
            //background: palette.secondary.main,
            color: palette.primary.dark,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          // Name of the slot
          root: {
            //borderColor: palette.secondary.dark,
            //background: palette.secondary.main,
            color: palette.primary.dark,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          // Name of the slot
          root: {
            //background:palette.primary.main
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          // Name of the slot
          root: {
            color: palette.background.contrastText,
            fontFamily: fontFamily, // Apply Urbanist to all typography components
            //color: palette.primary.contrastText,
            "&.login": {
              color: palette.primary.contrastText,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            //background:palette.primary.light,
          },
          input: {
            paddingTop: "6px",
            paddingBottom: "6px",
            //height:'20px', => add separate class
            ":-internal-autofill-selected": {
              backgroundColor: "#000 !important", // Override user agent styles with !important
              // Add any other custom styles here
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          // Name of the slot
          root: {
            padding: 0,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          // Name of the slot
          label: {
            //fontSize: '1.2rem',
            //paddingTop: '2px',
            //padding: 0,
          },
          "&.mobile": {
            // Additional styles for the subclass
            // Example:
            fontSize: "0.875rem",
            paddingTop: "0px",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1rem",
            "&.header": {
              // Additional styles for the subclass
              // Example:
              padding: "0px",
              margin: "0px",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&.header": {
              //backgroundColor: palette.background.paper,
              //boxShadow: "none",
              boxShadow: "var(--ox-shadows-3)",
              borderRadius: "0px",
              position: "sticky",
              top: 0,
              zIndex: 1000,
              // borderBottom: "5px",
              //borderColor: "black",
              paddingRight: "16px",
              paddingLeft: "16px",
              //color: palette.text.primary,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          // Name of the slot
          option: {
            "&.selectorAuto": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          // Name of the slot
          root: {
            "&.header": {
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            //borderRadius: '20px',
            "&.login": {
              // Additional styles for the subclass
              // Example:
              borderRadius: "20px",
            },
            //   '&.header': {
            //     backgroundColor: theme.palette.background.paper,
            //     boxShadow: "none",
            //     borderRadius: "0px",
            //     paddingRight: "16px",
            //     paddingLeft: "16px",
            //     color: theme.palette.text.primary,
            //   },
          },
        },
      },
    },
  });

  return theme;
}
