import React, { useState, useEffect  } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DateRangeIcon from '@mui/icons-material/DateRange';
import i18next from 'i18next';

import { DATERANGES, hotTranslate } from '../../constants';
import { setDateTimeOnKey } from "../Generic/RangePicker"
import { useDrawerWidth } from '../../size';

// input: points={{changeHandler: handlePointChange, defaultVal:chartObject.selectedPointIds, allVal:chartObject.pointsData}}
export default function ChartSelectRangeButton({ range }) {
    
    // Anchor states
    const [anchor, setAnchor] = useState(null);
    const menuOpen = Boolean(anchor);
    const drawerWidth = useDrawerWidth()
    // Data states
    const [rangeType, setRangeType] = useState(() => {
        const x =  DATERANGES.map(obj => {
            if (obj.value === range.defaultVal) return { ...obj, selected: true }
            else return { ...obj, selected: false }
        })
        return hotTranslate(x)
    });

    // Hot language change
    useEffect(() => {
        const languageChangedHandler = () => {
            setRangeType(prevRangeType => hotTranslate(prevRangeType));
        };
        
        i18next.on('languageChanged', languageChangedHandler);
    
        return () => {
          i18next.off('languageChanged', languageChangedHandler);
        };
      }, []);
    

    
    // Handlers
    // Handle all clicks on buttons
    const handleClick = (event) => {
        setAnchor(event.currentTarget);

    };
    const handleClose = () => {
        setAnchor(null);
        // return data to handler
        //points.changeHandler(allWithSelections.filter(f=> f.selected).map(m=> m.id))
    }

    const handleRange = (val) => {
        let updatedRange = rangeType.map(obj => {
            if (obj.id === val.target.id) return { ...obj, selected: true }
            else return { ...obj, selected: false }
        })
        let thisElement = updatedRange.find(f => f.selected === true)
        setRangeType(updatedRange)
        const newObject = setDateTimeOnKey(thisElement.value)

        // call handler
        range.changeHandler({ ...newObject, rangeType: thisElement.value })
    }

    return (
        <>
            <Button
                id="range-button"
                //startIcon={drawerWidth !== 0 && <DateRangeIcon />}
                aria-controls={menuOpen ? 'range-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleClick}
                //
            >
                {drawerWidth !== 0 && (
                    <div style={{  paddingLeft:"4px", paddingRight: '4px', display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/* <DateRangeIcon style={{paddingLeft:"4px", paddingRight: '4px'}}/> */}
                        {rangeType.find(k => k.selected === true).text}
                    </div>
                )}
                {drawerWidth === 0 && (
                    <DateRangeIcon />
                )}
            </Button>
            <Menu
                id="range-menu"
                anchorEl={anchor}
                open={menuOpen}
                onClick={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'range-button',
                }}
            >
                {rangeType.map(obj => (
                    <MenuItem key={obj.id} id={obj.id} onClick={handleRange}>{obj.text}</MenuItem>
                ))

                }
            </Menu>
        </>
    );
}