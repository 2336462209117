import React from "react";
import { Link } from "react-router-dom";
import {
    Divider,
    Box,
    Drawer,
} from "@mui/material";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MapIcon from '@mui/icons-material/Map';
import { HEADER_HEIGHT } from '../../constants';
import { useDrawerWidth } from '../../size';
import { useTranslation } from "react-i18next";

// SIDE MENU DESCRIPTION
// -----------
// - Upper section with menu items valid for all pages, for example go to map
// - Lower section with individual items for the specific pages (child element)
// - The logo is moved to the header, as it needs more width
// - For mobile screens, the menu is moved to the bottom, TODO: Limit number of individual icons, and add a selector

const SideMenu = ({ children, menuEntries }) => {
    const { t } = useTranslation();
    // const theme = useTheme();
    const drawerWidth = useDrawerWidth()

    const headerSize = parseInt(HEADER_HEIGHT)

    const menuListStyle = (type) => {
        return {
            display: 'flex',
            flexDirection: type === "small" ? "row" : 'column',
            paddingTop: type === "small" ? 0 : 'auto',
            paddingBottom: type === "small" ? 0 : 'auto',
            width: type === "small" ? `auto` : HEADER_HEIGHT,
        }
    }
    // const menuItemStyle = (type) => {
    //     return {

    //         "& .MuiMenuItem-root": {
    //             paddingRight: type === "small" ? null : `${0.1 * headerSize}px`,
    //             paddingLeft: type === "small" ? null : `${0.1 * headerSize}px`,
    //             //backgroundColor: '#00ff00'
    //         }
    //     }
    // }
    const iconScaleFactor = 0.6
    const svgIconStyles = (type) => {
        return {
            '& .MuiSvgIcon-root': {
                height: type === "small" ? `${iconScaleFactor * headerSize}px` : `${iconScaleFactor * headerSize}px`,
                width: type === "small" ? `${iconScaleFactor * headerSize}px` : `${iconScaleFactor * headerSize}px`,
            },
        }
    };
    // const listItemIconStyle = (type) => {
    //     return {
    //         minWidth: type === "small" ? '0px' : '32px',

    //         "&.MuiSvgIcon-root": {
    //             height:  type === "small" ? '1.5em' : 'auto',
    //         width:  type === "small" ? '1.5em' : 'auto',
    //         }
    //         //color: type !== "small" ? theme.palette.background.contrastText : theme.palette.primary.contrastText,

    //     }
    // }
    // const listItemTextStyle = (type) => {
    //     return {

    //         //color: theme.palette.background.contrastText,

    //     }
    // }
    const dividerStype = (type) => {
        return {
            //borderColor: theme.palette.primary.light,
        }
    }
    const drawerStyles = (type) => {
        return {
            //color: theme.palette.background.contrastText,
            //color: type !== "small" ? theme.palette.background.contrastText : theme.palette.secondary.contrastText,
            //background: type !== "small" ? `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.grey})` : theme.palette.secondary.dark,
        }
    }

    // const shortenText = (text) => {
    //     if (text.length > 7) {
    //         return text.substring(0, 10) + '...';
    //     } else {
    //         return text;
    //     }
    // };

    const staticMenuItems = () => {
        return [
            {
                icon: <MapIcon fontSize="small" />,
                link: `/campuses`,
                text: t("MAP")
            }, {
                icon: <EqualizerIcon fontSize="small" />,
                link: `/charts`,
                text: t("CHARTS")
            }, {
                icon: <AssignmentIcon fontSize="small" />,
                link: `/tasks`,
                text: t("TASKS")
            }
        ]
    }
    const StaticMenuComponents = ({ type }) => {
        return (
            <>
                {staticMenuItems().map((item, index) => (
                    <Tooltip title={item.text} key={index} placement={type === "small" ? "top" : "right"}>
                        <MenuItem
                            //sx={menuItemStyle(type)}
                            style={{
                                paddingRight: type === "small" ? null : `${(1-iconScaleFactor) *0.5 * headerSize}px`,
                                paddingLeft: type === "small" ? null : `${(1-iconScaleFactor) *0.5 * headerSize}px`,
                            }}

                            //className="sideMenu"
                            component={Link}
                            to={item.link}
                            key={index}
                        >
                            <ListItemIcon
                                //style={listItemIconStyle(type)}
                                sx={svgIconStyles(type)}
                            >
                                {item.icon}
                            </ListItemIcon>
                            {/* {(type !== "small" &&
                            // <ListItemText style={listItemTextStyle(type)}>{item.text}</ListItemText>
                            item.text
                        )} */}

                        </MenuItem>
                    </Tooltip>
                ))}
            </>

        )
    }

    const DrawerContent = ({ menuEntries, type }) => {

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: type === "small" ? "row-reverse" : "column",
                    //alignItems: type === "small" ? 'center' : "left",
                    //alignContent: type === "small" ? 'center' : "left",
                    justifyContent: "flex-start",
                    margin: type === "small" ? 'auto 0px' : '0px auto'
                }}
            >

                <MenuList 
                    sx={menuListStyle(type)}
                
                >
                    <StaticMenuComponents type={type} />
                </MenuList>
                <Divider
                    orientation={type === "small" ? "vertical" : 'horizontal'}
                    variant="middle"
                    flexItem
                    sx={dividerStype(type)} />

                <MenuList
                    sx={menuListStyle(type)}

                >
                    {menuEntries !== undefined && menuEntries !== null && menuEntries.length > 0 && (
                        menuEntries.map((element, index) => {
                            return (
                                <Tooltip title={element.text} key={index} placement={type === "small" ? "top" : "right"}>
                                    <MenuItem
                                        sx={
                                            (type)}
                                        component={Link}
                                        to={element.link}
                                        key={index}
                                        style={{
                                            paddingRight: type === "small" ? null : `${(1-iconScaleFactor) *0.5 * headerSize}px`,
                                            paddingLeft: type === "small" ? null : `${(1-iconScaleFactor) *0.5 * headerSize}px`,
                                        }}
                                    >

                                        <ListItemIcon
                                            sx={svgIconStyles(type)}>
                                            {element.icon}
                                        </ListItemIcon>
                                        {/* {(type !== "small" &&
                                            <ListItemText style={listItemTextStyle(type)}>{shortenText(element.text)}</ListItemText>
                                        )} */}

                                    </MenuItem>
                                </Tooltip>
                            )
                        })
                    )}
                </MenuList>

            </Box>
        )
    }


    return (
        // <Box sx={{ flexGrow: 1, margin: "auto", mt: 4, mb: 4 }}>
        <Box >
            {(drawerWidth === 0 &&

                <Drawer
                    variant="permanent" // temporary, permanent, persistent
                    anchor="bottom"
                    sx={{
                        [`& .MuiDrawer-paper`]: {
                            // height: HEADER_HEIGHT,
                            height: HEADER_HEIGHT,
                            padding: 0,
                            margin: 0,
                            width: '100%',
                            boxSizing: 'border-box',
                            boxShadow: 'var(--ox-shadows-3)',
                            ...drawerStyles("small"),

                        },
                    }}
                >
                    <DrawerContent menuEntries={menuEntries} type="small" />
                </Drawer>
            )}
            {(drawerWidth > 0 &&

                <Drawer
                    variant="permanent"
                    anchor="left"
                    sx={{
                        [`& .MuiDrawer-paper`]: {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            boxShadow: 'var(--ox-shadows-3)',
                            marginTop: `calc(${HEADER_HEIGHT} + 1px)`, //see workspace, must be same
                            //background:theme.palette.primary.main,
                            //color:theme.palette.primary.contrastText,
                            ...drawerStyles("normal"),
                        },

                    }}
                >
                    <DrawerContent menuEntries={menuEntries} type="normal" />
                </Drawer>

            )}
        </Box>
    )
}
export default SideMenu