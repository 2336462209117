import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Tooltip,
  Typography,
  Button,
  Avatar,
  ListItemAvatar,
  Divider,
  IconButton,
  useTheme,
  styled,
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import Workspace from '../PageStructure/Workspace';
import QuillEditor from '../Generic/QuillEditor';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  fetchTaskComments,
  postTaskComment,
  patchTaskComment,
  deleteTaskComment
} from '../actions/api'
import { stringAvatar } from '../Generic/genericFuncs';
import { useDrawerWidth } from '../../size';

// STYLING
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function DetailTask() {

  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const drawerWidth = useDrawerWidth()

  const [comments, setComments] = useState([]);
  const [notExpanded, setNotExpanded] = useState([]); // inverse logic, if value is included => not expanded



  function toggleElementInArray(array, element) {
    const index = array.indexOf(element);
    if (index !== -1) {
      return array.filter(item => item !== element);
    } else {
      return [...array, element];
    }
  }
  const handleAccordeonChange = (panel) => (event) => {
    const newArray = toggleElementInArray(notExpanded, panel);
    setNotExpanded([...newArray]);

  };


  const handleCancel = () => {
    navigate("/tasks");
  }


  useEffect(() => {
    const fetchComments = async (taskId) => {
      const data = await fetchTaskComments(taskId)
      const commentsParsed = data.comments.map(m => { return { ...m, text: JSON.parse(m.text) } })
      const updatedData = { ...data, comments: commentsParsed }
      setTask(updatedData);
      setComments(updatedData.comments);
    };

    fetchComments(id);
  }, [id]);



  // Receive the contents of the editor, with formatting data, represented by a Delta object
  const handleCommentSubmit = async (content, commentId) => {

    const text = JSON.stringify(content)
    if (commentId === undefined) {
      let data = await postTaskComment({ text: text, task: id })
      data["text"] = JSON.parse(data.text)
      setComments([...comments, data]);
    }
    else {
      let data = await patchTaskComment({ text: text, task: id }, commentId)
      data["text"] = JSON.parse(data.text)
      const updatedComments = comments.map((comment) =>
        comment.id === commentId ? data : comment
      );
      setComments(updatedComments);
    }
  };


  const handleDeleteComment = async (commentId) => {
    const data = await deleteTaskComment(id, commentId)
    if (data) {
      const updatedComments = comments.filter(
        (comment) => comment.id !== commentId
      );
      setComments(updatedComments);
    }
    else console.error("Error deleting comment."); // TODO add generic error handling/communication
  };


  const sortedComments = comments.slice().sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at); // latest first
  });
  // TODO consider locale of user (language, country-code)
  const AccordionRows = () => {
    return (
      <>

        {sortedComments.map((comment, index) => {

          // parse date
          const date = new Date(comment.created_at)
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
          const dateFormatted = date.toLocaleDateString('de-CH', options);
          return (
            <Accordion expanded={!notExpanded.includes(`panel${index}`)} key={`panel${index}`} onChange={handleAccordeonChange(`panel${index}`)}>
              <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                <div style={{ display: "flex", flexDirection: "row", width: '100%', height: "48px", alignItems: "center", justifyContent: "space-between", }}>
                  <div style={{ display: "flex", flexDirection: "row", }}>
                    <ListItemAvatar sx={{ minWidth: '30px' }}>
                      <Tooltip title={task.campus_assigned_users.find(k => k.id === comment.user).username} key={`acc-tt-${index}`}>
                        <Avatar
                          key={`acc-av-${index}`}
                          //sx={{ width: '20px', height: '20px' }}
                          style={{ width: '25px', height: '25px', fontSize: '0.8125rem' }}
                          alt={task.campus_assigned_users.find(k => k.id === comment.user).username}
                          src={`/api/avatar/${task.campus_assigned_users.find(k => k.id === comment.user).id}`}

                          {...stringAvatar(task.campus_assigned_users.find(k => k.id === comment.user).username)}
                        />
                      </Tooltip>

                    </ListItemAvatar>
                    {drawerWidth > 100 && (
                      <div style={{ paddingLeft: '0.2rem' }}>


                        <Typography variant="body">
                          {task.campus_assigned_users.find(k => k.id === comment.user).username}
                        </Typography>
                      </div>
                    )}

                    <div style={{ paddingLeft: '1rem' }}>


                      <Typography variant="body">
                        {dateFormatted}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <IconButton className="workspace" variant="contained" onClick={() => handleDeleteComment(comment.id)} sx={{}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>

              </AccordionSummary>
              <AccordionDetails>


                <QuillEditor existingDeltaObject={comment.text} commentId={comment.id} handleCommit={handleCommentSubmit} />
              </AccordionDetails>

            </Accordion>
          )
        })}
      </>
    )
  }


  const deltaDefaultObject = {
    ops: [
      { insert: `${t("OBSERVATION")}\n`, attributes: { header: 3, color: theme.palette.primary.dark } },
      { insert: '?\n' },
      { insert: `${t("MEASURE")}\n`, attributes: { header: 3, color: theme.palette.primary.dark } },
      { insert: '?\n' },
    ]
  }


  const title = (task === null) ? "" : task.title
  return (
    <Workspace
      isLoading={false}
      titleIcon={<EditNoteIcon color="primary" sx={{ fontSize: 40 }} />}
      title={`${title}`}
    //addTitleElementRightSide={<AddEntry />}
    >
      {/* Load last entry */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {t("NEW_ENTRY")}
        </Typography>
        <QuillEditor existingDeltaObject={deltaDefaultObject} handleCommit={handleCommentSubmit} />
        <Divider orientation="horizontal" flexItem />

        <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
          <Button className="workspace" variant="contained" onClick={handleCancel} startIcon={<CloseIcon />} sx={{ marginTop: "1rem", marginLeft: "8px" }}>
            {t("RETURN")}
          </Button>
        </div>
        <div style={{ paddingTop: '2rem' }}>
          {comments.length > 0 && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                {t("EXISTING_ENTRY")}
              </Typography>
              <AccordionRows />
            </>
          )}
        </div>
      </div>
    </Workspace>
  )
}

export default DetailTask;
