import React, { useEffect, useState } from "react";
import {
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import { useDrawerWidth } from "../../size";
import { styled } from "@mui/material/styles";

import "react-multi-date-picker/styles/layouts/mobile.css";

// Define custom CSS styles using styled
const CalendarWrapper = styled("div")(({ theme }) => ({
  "--primary-font": "Urbanist, sans-serif",
  "--rmdp-primary-calendarColor": theme.palette.secondary.main,
  "--rmdp-secondary-calendarColor": theme.palette.secondary.light,
  "--rmdp-secondary-calendarColor-text": theme.palette.secondary.dark,
  "--rmdp-shadow-calendarColor": "#87ad92",
  "--rmdp-shadow-calendarColor-hover": theme.palette.info.main,
  "--rmdp-today-calendarColor": theme.palette.primary.main,
  "--rmdp-hover-calendarColor": "#2ecc40",
  "--rmdp-deselect-calendarColor": theme.palette.secondary.dark,
  "& .rmdp-wrapper": {
    border: "1px solid var(--rmdp-secondary-calendarColor)",
    boxShadow: "0 0 5px var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-range": {
    backgroundColor: "1px solid var(--rmdp-secondary-calendarColor)",
    color: "var(--rmdp-secondary-calendarColor-text)",
    boxShadow: "0 0 5px var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-panel-body li": {
    backgroundColor: "var(--rmdp-primary-calendarColor)",
    boxShadow: "0 0 2px var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-week-day": {
    color: "var(--rmdp-primary-calendarColor)",
  },
  "& .rmdp-day.rmdp-deactive": {
    color: "var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-range-hover": {
    backgroundColor: "var(--rmdp-shadow-calendarColor-hover)",
    boxShadow: "0 0 3px var(--rmdp-shadow-calendarColor-hover)",
  },
  "& .rmdp-arrow": {
    border: "solid var(--rmdp-primary-calendarColor)",
    borderWidth: "0 2px 2px 0",
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "var(--rmdp-primary-calendarColor)",
    boxShadow: "0 0 3px var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-panel-body::-webkit-scrollbar-thumb": {
    background: "var(--rmdp-primary-calendarColor)",
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "var(--rmdp-today-calendarColor)",
  },
  "& .rmdp-rtl .rmdp-panel": {
    borderLeft: "unset",
    borderRight: "1px solid var(--rmdp-secondary-calendarColor)",
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: "var(--rmdp-primary-calendarColor)",
    boxShadow: "0 0 3px var(--rmdp-shadow-calendarColor)",
  },
  "& .rmdp-day:not(.rmdp-day-hidden) span:hover": {
    backgroundColor: "var(--rmdp-hover-calendarColor) !important",
  },
  "& .b-deselect": {
    color: "var(--rmdp-deselect-calendarColor)",
    backgroundColor: "white",
  },
  "& .rmdp-action-button": {
    color: "var(--rmdp-primary-calendarColor)",
  },
  "& .rmdp-button:not(.rmdp-action-button)": {
    backgroundColor: "var(--rmdp-primary-calendarColor)",
  },
  "& .rmdp-button:not(.rmdp-action-button):hover": {
    backgroundColor: "var(--rmdp-deselect-calendarColor)",
  },
}));

function FullYearPlugin({ DatePicker, fullYear, setFullYear }) {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth();

  // Full year not possible on small screens
  if (drawerWidth === 0) {
    setFullYear(false);
  }
  return (
    <div
      style={{
        paddingBottom: "0rem",
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <IconButton
        aria-label="close"
        disabled={!DatePicker}
        onClick={() => DatePicker.closeCalendar()}
      >
        <CloseOutlinedIcon />
      </IconButton>

      {drawerWidth !== 0 && (
        <FormGroup style={{ paddingLeft: "1rem" }}>
          <FormControlLabel
            onChange={() => setFullYear(!fullYear)}
            control={<Checkbox />}
            label={t("FULL_YEAR")}
          />
        </FormGroup>
      )}
    </div>
  );
}

export const RangePicker = ({ disabled = false, dateTime }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [values, setValues] = useState([]);
  const [fullYear, setFullYear] = useState(false);

  // State updates
  useEffect(() => {
    if (dateTime.defaultVal.startDateTime !== null) {
      const start = dateTime.defaultVal.startDateTime;
      const end = dateTime.defaultVal.endDateTime;
      const dateStart = new DateObject(start);
      const dateEnd = new DateObject(end);

      setValues([dateStart, dateEnd]);
    }
  }, [dateTime.defaultVal.startDateTime, dateTime.defaultVal.endDateTime]);

  const handleOnClose = () => {
    if (values.length === 2) {
      dateTime.changeHandler({
        startDate: values[0].format("YYYY-MM-DD"),
        startTime: values[0].format("00:00"),
        startDateTime: values[0].format("YYYY-MM-DDT00:00:00.000Z"),
        endDate: values[1].format("YYYY-MM-DD"),
        endTime: values[1].format("23:59"),
        endDateTime: values[1].format("YYYY-MM-DDT23:59:59.000Z"),
      });
    }

    if (values.length === 1) {
      dateTime.changeHandler({
        startDate: values[0].format("YYYY-MM-DD"),
        startTime: values[0].format("00:00"),
        startDateTime: values[0].format("YYYY-MM-DDT00:00:00.000Z"),
        endDate: values[0].format("YYYY-MM-DD"),
        endTime: values[0].format("23:59"),
        endDateTime: values[0].format("YYYY-MM-DDT23:59:59.000Z"),
      });
    }
  };

  return (
    <Box
      sx={{
        pointerEvents: disabled ? "none" : null,
        opacity: disabled ? 0.5 : 1,
        alignSelf: "center",
        paddingBottom: "1rem",
      }}
    >
      <CalendarWrapper>
        <DatePicker
          value={values}
          disabled={disabled}
          onClose={handleOnClose}
          className="custom-calendar"
          weekStartDayIndex={JSON.parse(t("WEEKSTARTINDEX"))}
          onChange={setValues}
          numberOfMonths={1}
          displayWeekNumbers
          weekNumber="W"
          fullYear={fullYear}
          showOtherDays
          range
          rangeHover
          plugins={[
            <FullYearPlugin
              position="top"
              fullYear={fullYear}
              setFullYear={setFullYear}
            />,
          ]}
          format={t("CALENDAR_FORMAT")}
          weekDays={JSON.parse(t("WEEKDAYS"))}
          months={JSON.parse(t("MONTHS"))}
          minDate="2020/01/01"
          dateSeparator=" - "
          maxDate={new DateObject().add(7, "days")}
          containerStyle={{ width: "220px" }}
          style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: "medium",
            paddingLeft: "1rem",
            width: "200px",
            backgroundColor: theme.palette.background.paper,
            height: "36px",
            boxSizing: "border-box",
          }}
        />
      </CalendarWrapper>
    </Box>
  );
};

export const setDateTimeOnKey = (keyString) => {
  let range;
  switch (keyString) {
    case "daily":
      range = [new DateObject().subtract(1, "days"), new DateObject()];
      break;
    case "weekly":
      range = [new DateObject().subtract(7, "days"), new DateObject()];
      break;
    case "monthly":
      range = [new DateObject().toFirstOfMonth(), new DateObject()];
      break;
    case "quarterly":
      range = [new DateObject().subtract(90, "days"), new DateObject()];
      break;
    case "yearly":
      range = [new DateObject().subtract(365, "days"), new DateObject()];
      break;
    default:
      range = [new DateObject().subtract(7, "days"), new DateObject()];
  }

  return {
    startDate: range[0].format("YYYY-MM-DD"),
    startTime: range[0].format("00:00"),
    startDateTime: range[0].format("YYYY-MM-DDT00:00:00:00Z"),
    endDate: range[1].format("YYYY-MM-DD"),
    endTime: range[1].format("23:59"),
    endDateTime: range[1].format("YYYY-MM-DDT23:59:59:00Z"),
  };
};
