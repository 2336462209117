import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import TaskEditMask from "./TaskEditMask";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchUserAccessibleCampuses,
  fetchCampusAssignedUsers,
  postNewTasks,
} from "../actions/api";

function CreateTask() {
  const { t } = useTranslation();
  const [task, setTask] = useState(() => {
    const saved = localStorage.getItem("selectedCampus"); // is array
    const obj = JSON.parse(saved);

    return {
      title: "",
      due_date: "",
      priority: { text: "", api: "", key: "" },
      status: { text: "", api: "", key: "" },
      task_group: { text: "", api: "", key: "" },
      assigned_to: [],
      campus: obj === null || obj.length === 0 ? { id: "" } : obj[0], // preselect first element
    };
  });

  const [campuses, setCampuses] = useState(() => {
    const saved = localStorage.getItem("selectedCampus");
    return saved === null || saved.length === 0
      ? []
      : [JSON.parse(saved)].flat().map((camp) => {
          return { ...camp, label: camp.name };
        });
  });
  const [campusUsers, setCampusUsers] = useState([]);
  const [allowAssignedTo, setAllowAssignedTo] = useState(false);
  const [updateFailed, setupUpdateFailed] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchUserAccessibleCampuses();
      setCampuses(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async (id) => {
      const data = await fetchCampusAssignedUsers(id);
      setCampusUsers(data);
      setAllowAssignedTo(true);
    };
    if (task.campus.id !== "") {
      fetchData(task.campus.id);
    }
  }, [task.campus]);

  const handleCreateTask = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // bring task into the right form
    const modData = {
      title: task.title,
      due_date: task.due_date,
      priority: task.priority.api,
      status: task.status.api,
      task_group: task.task_group.api,
      assigned_to: task.assigned_to,
      campus: task.campus.id,
    };

    const result = await postNewTasks(modData);
    setupUpdateFailed(result !== true);
  };

  useEffect(() => {
    if (updateFailed === true) {
      // do nothing
    } else if (updateFailed === false) {
      navigate("/tasks");
    }
  }, [updateFailed, navigate]);

  return (
    <Workspace
      isLoading={false}
      title={t("CREATE TASK")}
      titleIcon={<AddIcon color="primary" sx={{ fontSize: 40 }} />}
      addTitleElementRightSide={null}
    >
      <Box sx={{ maxWidth: "800px" }}>
        <form onSubmit={handleCreateTask}>
          <TaskEditMask
            val={task}
            setVal={setTask}
            campuses={campuses}
            campusUsers={campusUsers}
            buttonActionText={t("ADD")}
            allowAssign={allowAssignedTo}
          />
        </form>
      </Box>
      {updateFailed && (
        <Box sx={{ my: 2, color: "error.main" }}>
          {t("CREATION_FAILED_CHECK_ERRORS")}
        </Box>
      )}
    </Workspace>
  );
}

export default CreateTask;
