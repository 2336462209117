import React from "react";
import {
    Box,
    Divider,
    useTheme,
} from "@mui/material";
import CenteredCircularProgress from '../Generic/CenteredCircularProgress';
import PageTitle from '../Generic/PageTitle';
import { HEADER_HEIGHT } from '../../constants';
import { useDrawerWidth } from '../../size';

const Workspace = ({ children, isLoading, disableTitle = false, title, addTitleElementRightSide, titleIcon, getSize }) => {

    const theme = useTheme();
    const drawerWidth = useDrawerWidth()

    

    return (
        <>
      
        
        <Box id="workspace" sx={{ marginTop: '0', background: theme.palette.background.default }}>

            {(isLoading &&
                <CenteredCircularProgress sxProps={{
                    height: `calc(100vh - ${HEADER_HEIGHT})`,
                    marginLeft: `${drawerWidth}px`,

                }} />
            )}
            {(!isLoading &&


                <Box sx={{
                    height: `calc(100vh - ${HEADER_HEIGHT} - ${"1px"})`, // one px to much? divider?
                    margin: 0,
                    marginLeft: `${drawerWidth}px`,
                    paddingLeft: disableTitle ? '0px' : '1rem',
                    paddingRight: disableTitle ? '0px' : '1rem',
                    paddingBottom: drawerWidth === 0 ? HEADER_HEIGHT : 0,
                    

                }}>
                    {(!disableTitle &&
                        <>

                            <PageTitle rightSideElement={addTitleElementRightSide}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {titleIcon !== undefined && (
                                        <div style={{ paddingRight: '1rem' }}>
                                            {titleIcon}
                                        </div>
                                    )}
                                    {title}
                                </div>
                            </PageTitle>

                            <Divider sx={{

                                marginBottom: `1rem`,
                            }} />

                        </>
                    )}
                    {children}
                </Box>



            )}
        </Box>
        </>
    );
};

export default Workspace;