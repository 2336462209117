import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Divider,
  Paper,
  Grid,
  Link,
  Box,
  Stack,
  InputAdornment,
  FormControl,
  IconButton,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme } from "@mui/material/styles";
import { PiUserCircleFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LanguageSelector from "./LanguageSelector";
import { useFormSize } from "../../size";
import axios from "axios";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const handleLogin = async () => {
    try {
      setError("");
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/accounts/login/`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem("token", data.token);

        // Save the language to the backend
        saveUserLanguage(data.token);

        // Redirect to the "Campuses" page
        navigate("/campuses");
      } else {
        setError(t("loginNotSuccesful"));
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      setError(t("loginError"));
      console.error("Login error:", error);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const saveUserLanguage = async (token) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/userprofile/detail/`,
        { language: lang },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving language:", error);
    }
  };

  const panelWidth = useFormSize().panelWidth;
  const inputPropStyle = {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    borderColor: theme.palette.primary.dark,
    borderRadius: "20px",
    fontSize: useFormSize().fontSizeField,
  };

  const textFieldStyle = {
    fontSize: useFormSize().fontSizeInput,
    letterSpacing: useFormSize().letterSpacingInput,
  };

  const inputAdormentStart = (icon) => {
    return (
      <InputAdornment position="start">
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{
              borderColor: theme.palette.primary.dark,
              borderWidth: "1px",
              marginBottom: "2px",
              marginTop: "2px",
              marginLeft: "8px",
              marginRight: "8px",
            }}
          />
        </div>
      </InputAdornment>
    );
  };

  return (
    <div
      style={{
        overflow: "overlay",
        height: "100vh",
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            height: {
              xs: "auto",
              sm: "auto",
              md: "100vh",
              lg: "100vh",
              xl: "100vh",
            },
            width: "100%",
            paddingBottom: "2rem",
          }}
          style={{ margin: 0 }}
        >
          <Grid item xs={10} sm={10} lg={6} style={{ paddingLeft: 0 }}>
            <Stack
              maxWidth="lg"
              spacing={{
                xs: 0,
                sm: -4,
                md: -4,
                lg: -5,
                xl: -6,
              }}
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: "center" }}
            >
              <Box
                component="img"
                src={`https://oxoia-platform.s3.eu-central-2.amazonaws.com/Oxoia-02.png`}
                alt="Oxoia Logo"
                sx={{ width: "100%", display: "flex" }}
              />
              <Typography
                align="right"
                variant="h1"
                sx={{
                  textTransform: "uppercase",
                  fontSize: useFormSize().fontSize,
                  fontWeight: 900,
                  letterSpacing: ".2rem",
                  visibility: useFormSize().visibilityDecoration,
                  height: useFormSize().heightDecoration,
                }}
              >
                {t("welcome")}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            style={{ paddingTop: "0px", paddingLeft: 0 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={2}
              sx={{ width: panelWidth, marginBottom: "5px" }}
            >
              <LanguageSelector integration="noAuth" />
            </Box>
            <Paper className="login" sx={{ width: panelWidth }}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <PiUserCircleFill
                  style={{
                    height: "150px",
                    width: "150px",
                    color: theme.palette.primary.dark,
                    marginTop: "1rem",
                  }}
                />

                <FormControl
                  sx={{
                    width: "80%",
                    "& .MuiInputBase-input": { padding: "10px" },
                  }}
                >
                  <form>
                    <TextField
                      fullWidth
                      id="input-username-textfield"
                      variant="outlined"
                      margin="dense"
                      autoComplete="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      sx={textFieldStyle}
                      InputProps={{
                        style: inputPropStyle,
                        startAdornment: inputAdormentStart(
                          <AccountCircle
                            fontSize={useFormSize().inputIconSize}
                            sx={{
                              color: theme.palette.primary.dark,
                              paddingRight: "2px",
                            }}
                          />
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      id="input-password-textfield"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      autoComplete="current-password"
                      margin="dense"
                      sx={textFieldStyle}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputLabelProps={{ style: { padding: "0px" } }}
                      InputProps={{
                        style: inputPropStyle,
                        startAdornment: inputAdormentStart(
                          <LockIcon
                            fontSize={useFormSize().inputIconSize}
                            sx={{
                              color: theme.palette.primary.dark,
                              paddingRight: "2px",
                            }}
                          />
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </FormControl>
              </Stack>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={4}
                sx={{ maxWidth: "500px" }}
              >
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                ></div>
              </Box>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Button
                  className="login"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    maxWidth: "50%",
                  }}
                  onClick={handleLogin}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    className="login"
                    sx={{
                      fontWeight: "900",
                    }}
                  >
                    {t("Login")}
                  </Typography>
                </Button>
                {error && (
                  <Typography variant="h6" align="center" color="error">
                    {error}
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                  sx={{
                    maxWidth: "500px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      alignItems: "right",
                      paddingRight: "5px",
                    }}
                  >
                    {t("noAccount")}
                  </Typography>
                  <Link
                    to="/register" // Specify the URL path for Register
                    onClick={() => navigate("/register")} // Use navigate to go to Register
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        fontSize: "1rem",
                        alignItems: "right",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      {t("registerHere")}
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
