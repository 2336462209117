import axios from "axios";
import historianData from '../../HistorianChart.json'

const token = localStorage.getItem("token");
const headers = {
    headers: {
        Authorization: `Token ${token}`,
    },
}
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`


/**
 * Carbone API Call: Prepare PDF.
 * See https://carbone.io/api-reference.html#carbone-cloud-api
 * @return {Object} Data
 *  */
const createPdf = async (data) => {
    try {
        throw Error("API not implemented")
    } catch (error) {
        console.error("Error fetching pdf from carbone:", error, data);
        return { campuses: [] };
    }
};



// TODO What is the difference to below api (called on a differnet page)
/**
 * Backend Call: Get all campuses for ??.
 * @return {Object} Data
 *  */
const fetchUserAccessibleCampuses = async () => {
    try {
        const response = await apiCaller("/api/customers/user-accessible-campuses/");
        return response ? response : [];
    } catch (error) {
        console.error("Error fetching user-accessible campuses:", error);
        return [];
    }
};
// TODO What is the difference to above api (called on a differnet page)
/**
 * Backend Call: Get all campuses for ??.
 * @return {Object} Data
 *  */
const fetchCustomerAccessibleCampus = async () => {
    try {
        const response = await apiCaller("/api/customers/customer/");
        return (response && response.results.length > 0) ? response.results[0] : { campuses: [] };
    } catch (error) {
        console.error("Error fetching customer-accessible campuses:", error);
        return { campuses: [] };
    }
};


/**
 * Backend Call: Get task by query.
 * @param {String} queryString - Query: campus_id=?,  task_group=?, works with one or two options
 * @return {Object} Data
 *  */
const fetchTasksWithQuery = async (queryString) => {
    try {
        const response = await apiCaller(`/api/customers/tasks/${queryString}`);
        return response ? response.results : [];
    } catch (error) {
        console.error("Error fetching tasks by query:", error);
        return [];
    }
};
/**
 * Backend Call: Post a new task.
 * @param {Object} data - Object with new data. Contains: title=String, due_date=String (DateTime), priority=String, status=String, task_group=String, assigned_to=?, campus=Number
 * @return {Boolean} True if successful, false if not
 *  */
const postNewTasks = async (data) => {

    try {
        const response = await apiPoster(`/api/customers/campuses/${data.campus}/tasks/`,data);
        
        return response.status === 201 ? true : false;
    } catch (error) {
        console.error("Error adding new tasks:", error);
        return false;
    }
};
/**
 * Backend Call: Get a task comments by query.
 * @param {Number} id - Task id
 * @return {Object} Data
 *  */
const fetchTaskComments = async (id) => {
    try {
        const response = await apiCaller(`/api/customers/tasks/${id}`);
        
        return response ? response : null;
    } catch (error) {
        console.error(`Error fetching comment from task ${id}:`, error);
        return [];
    }
};
/**
 * Backend Call: Post a new task comment
 * @param {Object} data - Object with new data. Contains: text=String, id=Number (task id)
 * @return {Object} Object with keys: id=Number, task=Number, text=String, user=Number, created_at
 *  */
const postTaskComment = async (data) => {

    try {
        const response = await apiPoster(`/api/customers/tasks/${data.task}/comments/`,data);
        if(response.data) return response.data
        else throw Error("Wrong response format")
    } catch (error) {
        console.error("Error adding new task comment:", error);
        return false;
    }
};
/**
 * Backend Call: Patch an existing task comment
 * @param {Object} data - Object with new data. Contains: text=String, id=Number (task id)
 * @param {Boolean} commentId - Task id
 * @return {Object} Object with keys: id=Number, task=Number, text=String, user=Number, created_at
 *  */
const patchTaskComment = async (data,commentId) => {

    try {
        const response = await apiPatcher(`/api/customers/tasks/${data.task}/comments/${commentId}/`,data);
        if(response.data) return response.data
        else throw Error("Wrong response format")
    } catch (error) {
        console.error("Error patching task comment:", error);
        return false;
    }
};
/**
 * Backend Call: Delete a task comment.
 * @param {Boolean} taskId - Task id
 * @param {Boolean} commentId - Task id
 * @return {Boolean} True if successful (status == 204), else false
 *  */
const deleteTaskComment = async (taskId,commentId) => {
    try {
        const response = await apiDeleter(`/api/customers/tasks/${taskId}/comments/${commentId}/delete/`);
        return response.status === 204 ? true : false;
    } catch (error) {
        console.error("Error deleting task comment:", error);
        return false;
    }
};
/**
 * Backend Call: Get users assigned to campus.
 * @param {String} campusId - Id of campus
 * @return {Object} Data
 *  */
const fetchCampusAssignedUsers = async (campusId) => {
    try {
        const response = await apiCaller(`/api/customers/campuses/${campusId}/`);
        return response ? response.assigned_users : [];
    } catch (error) {
        console.error("Error fetching users assigned to campus:", error);
        return [];
    }
};
/**
 * Backend Call: Get task by id.
 * @param {String} id - Task id 
 * @return {Object} Data
 *  */
const fetchTaskById = async (id) => {
    try {
        const response = await apiCaller(`/api/customers/tasks/${id}`);
        return response ? response : [];
    } catch (error) {
        console.error("Error fetching tasks by id:", error);
        return [];
    }
};
/**
 * Backend Call: Delete a task by id.
 * @param {String} id - Task id
 * @return {Boolean} True if successful (status == 204), else false
 *  */
const deleteTask = async (id) => {
    try {
        const response = await apiDeleter(`/api/customers/tasks/${id}/`);
        return response.status === 204 ? true : false;
    } catch (error) {
        console.error("Error fetching chart data from backend by query:", error);
        return false;
    }
};
/**
 * Backend Call: Patch task attributes.
 * @param {String} id - Task id
 * @param {String} data -  Object with updated values. 
 * Keys: 
 *  title: String,
 *  due_date: String 
 *  priority: String
 *  status: String
 *  task_group: String
 *  assigned_to: Array
 *  campus: String with id
 * @return {Boolean} True if successful (status == 200), else false
 *  */
const patchTaskMetaData = async (id, data) => {
    try {
        const response = await apiPatcher(`/api/customers/tasks/${id}/`, data);
        return response.status === 200 ? true : false;
    } catch (error) {
        console.error("Error patching tasks by id:", error);
        return false;
    }
};


/**
 * Backend Call: Get all chart descriptions (markdown).
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus -  Database id of campus
 *  */
const fetchChartDescription = async (customerID, selectedCampus) => {
    try {
        const response = await apiCaller(`/api/customers/customer/${customerID}/campuses/${selectedCampus}/get_charts`);
        return response ? response.results : [];
    } catch (error) {
        console.error("Error fetching chart data from backend by query:", error);
        return [];
    }
};
/**
 * Model Server Call: Get all chart view templates.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus -  Database id of campus
 *  */
const fetchChartViews = async (customerID, selectedCampus) => {
    try {
        const response = await apiCaller(`/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartViews`);
        if (response && response.error === "") {
            return response.response
        } else {
            console.error("Error on model server fetching chart data by query:", response.error);
            return []
        }

    } catch (error) {
        console.error("Error fetching chart data by query:", error);
        return [];
    }
};
/**
 * Model Server Call: Get targets from the model, the chart view applies to.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus -  Database id of campus
 * @param {String} command -  Comma separated string ('oxoia.base::OxFloor,@2d9eaece-bb7470ff') with target spec and ???
 *  */
const fetchChartTargets = async (customerID, selectedCampus, command) => {
    try {
        //return floors
        // TODO: change
        const response = await apiCaller(`/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartTarget/?oxoia_command=${encodeURIComponent(
            command
        )}`);
        if (response && response.error === "") {
            return response.response
        } else {
            console.error("Error on model server fetching chart data by query:", response.error);
            return []
        }

    } catch (error) {
        console.error("Error fetching chart targets by query:", error);
        return [];
    }
};
/**
 * Model Server Call: Get all points linked to the selected target.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus -  Database id of campus
 * @param {String} targetId -  Haystack id of target
 * @param {String} chartSpec -  Spec id of chart
 * @param {String} functionName -  Name of the function used by the model server to query the points
 * @return {Object} Returns an object with keys: chartType->String, colors->Array, points->Array, series->String
 *  */
const fetchChartPoints = async (customerID, selectedCampus, targetId, chartSpec, functionName) => {
    try {
        if (targetId === undefined || chartSpec === undefined || functionName === undefined) throw Error("Received undefined input argument 'targetId', 'chartSpec', 'functionName'.")

        const response = await apiCaller(`/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartPoints/?chart_id=${targetId}&spec=${chartSpec}&function_name=${functionName}`);

        if (response && response.error === "") {
            return response.response[0].data[0]
        } else {
            console.error("Error on model server fetching chart data by query:", response.error);
            return {}
        }

    } catch (error) {
        console.error("Error fetching chart points by query:", error);
        return {};
    }
};
const fetchChartTimeSeriesData = async (listOfIds, start, end) => {
    try {
        // TODO Add proper API with arguments
        //const response = await apiCaller(`todo`);
        //return response ? response.data.results : [];
        //const response = await fetch('../../HistorianChart.json'); // historianData
        //const jsonData = await response.json();
        return historianData
    } catch (error) {
        console.error("Error fetching chart data by query:", error);
        return [];
    }
};

// GENERIC
const apiCaller = async (path) => {

    try {
        const response = await axios.get(baseUrl + path, headers);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", "Path:", path, "Error:", error);
        throw error;
    }
}
const apiPatcher = async (path, data) => {

    try {
        const response = await axios.patch(baseUrl + path, data, headers);
        return response
    } catch (error) {
        console.error("Error patching data:", "Path:", path, "Error:", error);
        throw error;
    }
}
const apiDeleter = async (path) => {

    try {
        const response = await axios.delete(baseUrl + path, headers);
        return response
    } catch (error) {
        console.error("Error deleting data:", "Path:", path, "Error:", error);
        throw error;
    }
}
const apiPoster= async (path,data) => {

    try {
        const response = await axios.post(baseUrl + path, data, headers);
        return response
    } catch (error) {
        console.error("Error posting data:", "Path:", path, "Error:", error);
        throw error;
    }
}



export {
    createPdf,
    fetchUserAccessibleCampuses,
    fetchTasksWithQuery,
    fetchTaskById,
    fetchCampusAssignedUsers,
    deleteTask,
    postNewTasks,
    patchTaskMetaData,
    fetchTaskComments,
    postTaskComment,
    patchTaskComment,
    deleteTaskComment,
    fetchChartDescription,
    fetchChartTimeSeriesData,
    fetchCustomerAccessibleCampus,
    fetchChartViews,
    fetchChartTargets,
    fetchChartPoints,
}