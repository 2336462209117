import React from "react";
import ResizeablePlot from "./ResizeablePlot"; 

import {
    timeZoneData,
    syncCursers,
    wheelZoomPlugin,
    touchZoomPlugin,
    } from './uplotFuncs'



// TODO: format x-axis
 // eslint-disable-next-line no-unused-vars
let xAxis = {
    space: 40,
    height: 60,
    //label: "Population",
    //show:true,
    //labelFont: "bold 12px Urbanist",
    //font: "12px Urbanistt, sans-serif",
    side: 0,
    gap: 5,
    stroke: "red",
    class: "x-time",
    incrs: [
        // minute divisors (# of secs)
        1,
        5,
        10,
        15,
        30,
        // hour divisors
        60,
        60 * 5,
        60 * 10,
        60 * 15,
        60 * 30,
        // day divisors
        3600,
        // ...
    ],
    // [0]:   minimum num secs in found axis split (tick incr)
    // [1]:   default tick format
    // [2-7]: rollover tick formats
    // [8]:   mode: 0: replace [1] -> [2-7], 1: concat [1] + [2-7]
    values: [
        // tick incr          default           year                             month    day                        hour     min                sec       mode
        [3600 * 24 * 365, "{YYYY}", null, null, null, null, null, null, 1],
        [3600 * 24 * 28, "{MMM}", "\n{YYYY}", null, null, null, null, null, 1],
        [3600 * 24, "{M}/{D}", "\n{YYYY}", null, null, null, null, null, 1],
        [3600, "{h}{aa}", "\n{M}/{D}/{YY}", null, "\n{M}/{D}", null, null, null, 1],
        [60, "{h}:{mm}:{aa}", "\n{M}/{D}/{YY}", null, "\n{M}/{D}", null, null, null, 1],
        [1, ":{ss}", "\n{M}/{D}/{YY} {h}:{mm}{aa}", null, "\n{M}/{D} {h}:{mm}{aa}", null, "\n{h}:{mm}{aa}", null, 1],
        [0.001, ":{ss}.{fff}", "\n{M}/{D}/{YY} {h}:{mm}{aa}", null, "\n{M}/{D} {h}:{mm}{aa}", null, "\n{h}:{mm}{aa}", null, 1],
    ],
    grid: {
        color: "#eee",
        width: 2,
        show: true,
    },
    ticks: {
        show: true,
        stroke: "#eee",
        width: 2,
        dash: [],
        size: 10,
    },
    //  splits:
}


/**
 * A component that utilizes uPlot to render historical data with performance optimizations using hooks and batched updates.
 * Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb
 * @param {function} eventHandler - The event handler function.
 * @param {Array} xArray - The array of x-axis data. 
 * @param {Array} yArrays - The array of y-axis data. One or more series.
 * @param {Object} options - Chart options containing title (Str), chartType (Str), colors (Array), points (Array) with names
 *  */
const HistorianUPlot = ({ data, chartDefs, syncKey, tz, key,drawerWidth }) => {
    
    const sizeDefiningId = 'graphContainer'
    

    // Stop here, if no data (or show loading)
    if (data === undefined) return null
    
    
    const buildYSeries = chartDefs.points.map((point, index) => {
        return {
            // in-legend display
            label: point.dis,
            value: (self, rawValue) => (rawValue!==null) ? rawValue.toFixed(2) +"[unit]" : null,  //(u, v, sidx, didx) =>  (v!==null) ? v.toFixed(1) + "%" : null,
            
            points: { show: false },
            stroke: chartDefs.colors[index],
            
            fill: chartDefs.chartType === "area-chart" ? chartDefs.colors[index] : null,
            scale: "°C", //point.unit, // TODO: add to api
            //fontLabel: "12px Urbanist",

        }
    })

    // TODO: add unit, 
    const buildYAxes = chartDefs.points.map((point, index) => {
        // skip if no unit, if same unit
        return {
            //values: (u, vals, space) => vals.map(v => +v.toFixed(1) + "°C"),
            //scale: "°C", 
        }
    })

    const getStartingSize = () => {
        const parentElement = document.getElementById(sizeDefiningId);
        const parentWidth = parentElement.offsetWidth - drawerWidth //+40; // reduce the margin on the side (make it bigger)
        return {
            width: parentWidth,
            height: parentWidth / 2,
        };
    };
    

    const opts = {
        title: chartDefs.title,
        ...getStartingSize(),
        series: [
            {
                label: "Date"
            },
            ...buildYSeries
        ],
        // hooks:{
        //     setLegend: [ u => console.log('setLegend', u.legend.idxs,u) ],
        // },
        //plugins: [emptyPlugin()],
        plugins: [
            wheelZoomPlugin({factor: 0.75}),
            touchZoomPlugin()
        ],
        scales: {
            x: {
                time: true,
            }

        },
        ...timeZoneData(tz),
        ...syncCursers(syncKey),
        //...format("YYYY-MM-DD HH:mm:ss"),
        axes: [
            {},
            ...buildYAxes
        ],

    }
    
    // {
    //     label: "",
    //     points: { show: false },
    //     stroke: "blue",
    //     fill: "blue"
    //   }

    

    
    



    return  <ResizeablePlot options={opts} data={data} sizeDefiningId={'graphContainer'} widthCor={drawerWidth} />

};

export default HistorianUPlot