import React from "react";
import Stack from '@mui/material/Stack';


import { RangePicker } from "../Generic/RangePicker"
import { useDrawerWidth } from '../../size';
import ChartSelectRangeButton from "./ChartSelectRangeButton"
import ChartSaveButton from "./ChartSaveButton"



export default function ChartButtons({ range,  dateTime }) {

    const drawerWidth = useDrawerWidth()

    // Handle actions

    return (
        <div style={{ paddingTop: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: drawerWidth === 0 ? 'space-between' : 'left', flexWrap: 'wrap' }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <Stack
                    direction="row"
                    spacing={0.5}
                    style={{
                        paddingRight: "1rem", paddingBottom: "1rem",
                    }}>
                   
                    <ChartSelectRangeButton range={range} />
                </Stack>
                <RangePicker disabled={range.defaultVal !== "custom"} dateTime={dateTime} />


            </div>
            <div style={{ paddingBottom: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <Stack
                    direction="row"
                    spacing={0.5} >
                   
                    <ChartSaveButton  chartObject={dateTime.allVal}/>
                </Stack>
            </div>
        </div>
    );
}