import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { waitForElementById } from "../Generic/genericFuncs";
import { useDrawerWidth } from "../../size";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
  useTheme,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { FaFileAlt, FaShieldAlt } from "react-icons/fa";
import "leaflet/dist/leaflet.css";
import "../../index.css";
import apartmentIconPng from "../Assets/Mapicons/11 Commercial building.png";
import Workspace from "../PageStructure/Workspace";
import { useTranslation } from "react-i18next";

function Campuses({ addSubMenu }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const drawerWidth = useDrawerWidth();
  const [campuses, setCampuses] = useState([]);
  const [mapState, setMapState] = useState(null); //{ position: [47.3781, 8.5403], zoom: 9 }

  // Calculate map cordinates, returns position like [51.505, -0.09]
  const getZoomLevel = (bounds, mapWidthPx, mapHeightPx) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = { lat: bounds[1][0], lng: bounds[1][1] };
    const sw = { lat: bounds[0][0], lng: bounds[0][1] };

    const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    const lngDiff = ne.lng - sw.lng;
    const lngFraction = ((lngDiff + 360) % 360) / 360;

    const latZoom = zoom(mapHeightPx, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapWidthPx, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  const createCampEntries = useCallback(
    (camps) => {
      const items = [];
      camps?.forEach((element) =>
        items.push({
          icon: <ApartmentIcon />, // must be component
          text: element.name, // must be text
          link: `/campus-detail/${element.id}`, // must be text
        })
      );

      addSubMenu(items);
    },
    [addSubMenu]
  );

  const calcMapPosition = useCallback(
    async (camps) => {
      try {
        const workspaceMapElement = await waitForElementById("workspace", 5000);
        if (workspaceMapElement) {
          if (camps.length !== 0) {
            var pos = null;
            var zoom = null;
            const coordinates = camps.map((k) => [k.latitude, k.longitude]);

            const bounds = coordinates.reduce(
              (acc, coord) => {
                return [
                  [
                    Math.min(acc[0][0], coord[0]),
                    Math.min(acc[0][1], coord[1]),
                  ],
                  [
                    Math.max(acc[1][0], coord[0]),
                    Math.max(acc[1][1], coord[1]),
                  ],
                ];
              },
              [coordinates[0], coordinates[0]]
            );

            pos = [
              (bounds[0][0] + bounds[1][0]) / 2,
              (bounds[0][1] + bounds[1][1]) / 2,
            ];
            const mapWidthPx =
              document.getElementById("workspace").offsetWidth - drawerWidth;
            const mapHeightPx =
              document.getElementById("workspace").offsetHeight;

            zoom = getZoomLevel(bounds, mapWidthPx, mapHeightPx);

            setMapState({ position: pos, zoom: zoom - 1 });

            return true;
          } else {
            // no camps -> for switzerland
            setMapState({ position: [47.3781, 8.5403], zoom: 9 });
          }
        } else {
          console.error(
            "Workspace element not found or it is taking too long...",
            workspaceMapElement
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [drawerWidth]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/user-accessible-campuses/`;
    const apiCustomerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/customer/`;
    const apiPartnerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/partner-customers/`;

    const fetchData = async () => {
      // Build the array of fetch requests
      Promise.all([
        fetch(apiUrl, headers).then((res) => res.json()),
        fetch(apiCustomerUrl, headers).then((res) => res.json()),
        fetch(apiPartnerUrl, headers).then((res) => res.json()),
      ])
        .then(([campusData]) => {
          setCampuses(campusData);
          setIsLoading(false);

          // create icons/entries
          createCampEntries(campusData);

          // calculate map positions or if empty default map
          calcMapPosition(campusData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    };

    // Call the fetchData function
    fetchData();
  }, [calcMapPosition, createCampEntries]);

  // CUSTOM ICON
  // Example of creating a marker with custom text using L.DivIcon
  var customIcon = (text) => {
    return new L.DivIcon({
      // className: 'glow-icon',
      //iconUrl: apartmentIconPng,
      html: `
      <div style="position: relative; display: inline-block; margin-left:-5px; margin-top: -5px; min-width: 70px; box-shadow: 7px 10px 12px 4px rgba(0, 0, 0, 0.5);">
      <div style=" padding: 6px; padding-right: 14px; font-size: 12px; border: 1px solid #ccc; border-radius: 4px;background: ${theme.palette.primary.main};color:  ${theme.palette.primary.contrastText};">${text}</div>
      <img src="${apartmentIconPng}" alt="Icon" style="position: absolute; top: -15px; right: -15px; width: 30px; height: 30px; ">
    </div>
        `,
      iconSize: [1, 1], // Set the size of the icon (white box)
    });
  };

  const popupButtons = (link, camp) => {
    return [
      {
        icon: <FaFileAlt />,
        link: `/campus-detail/${link}`,
        text: t("DETAILS"),
        func: () => {
          camp === null
            ? localStorage.setItem("selectedCampus", JSON.stringify([]))
            : localStorage.setItem("selectedCampus", JSON.stringify([camp]));
        },
      },
      {
        icon: <EqualizerIcon />,
        link: `/charts`,
        text: t("CHARTS"),
        func: () => {
          camp === null
            ? localStorage.setItem("selectedCampus", JSON.stringify([]))
            : localStorage.setItem("selectedCampus", JSON.stringify([camp]));
        },
      },
      {
        icon: <AssignmentIcon />,
        link: `/tasks`,
        text: t("TASKS"),
        func: () => {
          camp === null
            ? localStorage.setItem("selectedCampus", JSON.stringify([]))
            : localStorage.setItem("selectedCampus", JSON.stringify([camp]));
        },
      },
      {
        icon: <FaShieldAlt />,
        link: `/campus-control/${link}`,
        text: t("CONTORL"),
        func: () => {
          camp === null
            ? localStorage.setItem("selectedCampus", JSON.stringify([]))
            : localStorage.setItem("selectedCampus", JSON.stringify([camp]));
        },
      },
    ];
  };

  const Map = ({ state }) => {
    return (
      <MapContainer
        center={state.position}
        zoom={state.zoom}
        id="workspaceMap"
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }} // Ensuring the map has a defined height
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {campuses.map((campus, index) => (
          <Marker
            key={index}
            position={[campus.latitude, campus.longitude]}
            icon={customIcon(campus.name)} // Adjust icon size if needed, test classes => TODO, icon with shadow, see leaflet
          >
            <Popup>
              <Card
                sx={{
                  maxWidth: 320,
                  overflow: "visible",
                  borderWidth: 0,
                  margin: 0,
                  padding: 0,
                }}
                variant="outlined"
              >
                <CardContent sx={{ margin: 0, padding: 0 }}>
                  <Typography variant="h6" component="div">
                    {campus.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {campus.description}
                  </Typography>
                  <Stack spacing={1}>
                    {popupButtons(campus.id, campus).map((element, index) => (
                      <Button
                        startIcon={element.icon}
                        component={Link}
                        onClick={element.func}
                        className="popup"
                        to={element.link}
                        fullWidth
                        variant="contained"
                        sx={{
                          mb: 1,
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                        }}
                        key={index}
                      >
                        {element.text}
                      </Button>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    );
  };

  return (
    <Workspace
      isLoading={isLoading || mapState === null ? true : false}
      disableTitle={true}
    >
      {mapState !== null && !isLoading && <Map state={mapState} />}
    </Workspace>
  );
}

export default Campuses;
