import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FeedbackIcon from "@mui/icons-material/FavoriteBorder";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import LanguageSelector from "../Accounts/LanguageSelector";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { HEADER_HEIGHT } from "../../constants";
import ColorSettings from "../Accounts/ColorSettings";
import axios from "axios";

const HeaderMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const HeaderContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
}));

const Header = ({ setColorList }) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [isUserInPartnerGroup, setIsUserInPartnerGroup] = useState(null);
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    if (token) {
      const fetchUserInfo = async () => {
        const apiloggedUserUrl = `${process.env.REACT_APP_API_BASE_URL}/api/accounts/get-user-info/`;
        try {
          const response = await fetch(apiloggedUserUrl, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setLoggedInUserEmail(data.user_name);
            saveUserLanguage(token);
          } else {
            console.error("Failed to fetch user info");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      };
      fetchUserInfo();
    } else {
      console.error("No token found in localStorage");
    }
  }, []);

  const saveUserLanguage = async (token) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customers/userprofile/detail/`,
        { language: lang },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving language:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const apiCustomerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/customer/`;
    const apiPartnerUrl = `${process.env.REACT_APP_API_BASE_URL}/api/customers/partner-customers/`;

    // Fetch customer and partner data
    Promise.all([
      fetch(apiCustomerUrl, headers).then((res) => res.json()),
      fetch(apiPartnerUrl, headers).then((res) => res.json()),
    ])
      .then(([customerData, partnerData]) => {
        if (customerData.results && customerData.results.length > 0) {
          setCustomers(customerData.results[0]);
        }
        if (partnerData && partnerData.length > 0) {
          setPartners(partnerData);
          const isUserInGroup = partnerData.some((partner) =>
            partner.users.some((user) => user.email === loggedInUserEmail)
          );
          setIsUserInPartnerGroup(isUserInGroup);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [loggedInUserEmail]);

  const handleMenuOpen = (event) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/login");
  };

  const SelectedIcon = () => {
    if (isUserInPartnerGroup) {
      return (
        <img
          src={partners[0]?.partner_icon}
          alt="Partner Logo"
          style={{
            marginRight: "30px",
            width: "auto",
            height: `calc(${HEADER_HEIGHT} - 20px )`,
          }}
        />
      );
    } else if (customers?.customer_icon) {
      return (
        <img
          src={customers?.customer_icon}
          alt="Customer Logo"
          style={{ width: "auto", height: `calc(${HEADER_HEIGHT} - 20px )` }}
        />
      );
    } else {
      console.log("No icon available to display.");
      return null;
    }
  };

  return (
    <AppBar position="static" className="header">
      <HeaderContainer maxWidth="true" className="header">
        <Toolbar disableGutters className="header">
          <SelectedIcon />

          <HeaderMenuButton
            edge="end"
            color="inherit"
            onClick={handleMenuOpen}
            size="medium"
            className="header"
          >
            <AccountBoxIcon />
          </HeaderMenuButton>
          {isLoggedIn && (
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              disableScrollLock
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={handleMenuClose}
              >
                <CloseIcon />
              </IconButton>
              <Container sx={{ marginTop: "10px", minWidth: "250px" }}>
                <Typography gutterBottom variant="h6">
                  {t("APPEARANCE")}
                </Typography>
              </Container>
              <ColorSettings
                setColorList={setColorList}
                handleClose={handleMenuClose}
              />
              <Container>
                <Typography gutterBottom variant="h6">
                  {t("QUICK ACCESS")}
                </Typography>
              </Container>
              <LanguageSelector integration={"header"} />

              <MenuItem
                onClick={() => {
                  navigate("/settings");
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                {t("SETTINGS")}
              </MenuItem>

              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                {t("FEEDBACK")}
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                {t("LOGOUT")}
              </MenuItem>
            </Menu>
          )}
        </Toolbar>
      </HeaderContainer>
      <Divider style={{ height: "1px" }}></Divider>
    </AppBar>
  );
};

export default Header;
