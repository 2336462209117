import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import Workspace from "../PageStructure/Workspace";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import TaskTable from "./TaskTable";
import TaskTopicFilter from "./TaskTopicFilter";
import { TASK_GROUPS, hotTranslate } from "../../constants";
import { useDrawerWidth } from "../../size";
import {
  fetchUserAccessibleCampuses,
  fetchTasksWithQuery,
} from "../actions/api";

function Tasks() {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campuses, setCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(() => {
    const saved = localStorage.getItem("selectedCampus");
    return saved === null || saved.length === 0
      ? []
      : [JSON.parse(saved)]
          .flat()
          .map((camp) => (camp ? { ...camp, label: camp.name } : {}));
  });
  const [selectedTaskTopics, setSelectedTaskTopics] = useState(() => {
    const saved = localStorage.getItem("selectedTaskTopics");
    return JSON.parse(saved) || hotTranslate(TASK_GROUPS);
  });
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message

  useEffect(() => {
    localStorage.setItem("selectedCampus", JSON.stringify(selectedCampus));
  }, [selectedCampus]);

  useEffect(() => {
    localStorage.setItem(
      "selectedTaskTopics",
      JSON.stringify(selectedTaskTopics)
    );
  }, [selectedTaskTopics]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserAccessibleCampuses();
        if (data.length > 0) {
          setCampuses(data);
          setIsLoading(false);
        } else {
          setErrorMessage(t("No campuses available for this user."));
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage(t("Error fetching campuses data."));
        setIsLoading(false);
      }
    };
    fetchData();
  }, [t]);

  const addOrUpdateArray = (arrayX, arrayY) => {
    arrayX.forEach((elementX) => {
      const index = arrayY.findIndex((elementY) => elementY.id === elementX.id);
      if (index !== -1) {
        arrayY[index] = elementX; // Overwrite existing element
      } else {
        arrayY.push(elementX); // Add new element
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const updatedTasks = [];
      for (const campus of selectedCampus) {
        for (const topic of TASK_GROUPS) {
          const queryParams = [];
          queryParams.push(`campus_id=${campus.id}`);
          queryParams.push(`task_group=${topic.api}`);
          const queryString = queryParams.length
            ? `?${queryParams.join("&")}`
            : "";
          addOrUpdateArray(
            await fetchTasksWithQuery(queryString),
            updatedTasks
          );
        }
      }
      setTasks(updatedTasks);
    };
    if (selectedCampus.length > 0) fetchData();
  }, [selectedCampus, selectedTaskTopics]);

  const AddEntry = () => {
    return (
      <Button
        variant="contained"
        className="workspace"
        disabled={selectedCampus.length === 0}
        startIcon={<AddIcon />}
        component={Link}
        to="/tasks/create"
      >
        {t("ADD")}
      </Button>
    );
  };

  return (
    <Workspace
      isLoading={isLoading}
      title={t("TASKS")}
      addTitleElementRightSide={campuses.length > 0 && <AddEntry />}
    >
      {errorMessage ? (
        <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
          {errorMessage}
        </Typography>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <GenericAutocomplete
              multiple={true}
              dataList={campuses || []}
              changeHandler={setSelectedCampus}
              value={selectedCampus}
              size={`${drawerWidth === 0 ? "small" : "medium"}`}
              style={{
                paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
                minWidth: `${drawerWidth === 0 ? "100%" : "150px"}`,
                width: `${drawerWidth === 0 ? "100%" : "250px"}`,
              }}
              label={t("Campus")}
            />
          </div>
          <TaskTopicFilter
            tasks={hotTranslate(TASK_GROUPS)}
            label={t("TOPICFILTER")}
            topicHandler={setSelectedTaskTopics}
            selectedTopics={selectedTaskTopics}
            style={{
              paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
              width: `${drawerWidth === 0 ? "100%" : "250px"}`,
            }}
          />
          <TaskTable
            rows={tasks}
            topicSelection={selectedTaskTopics}
          ></TaskTable>
        </>
      )}
    </Workspace>
  );
}

export default Tasks;
